import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { Maybe } from '@emico/graphql-schema-types'
import { ChevronRightIcon } from '@emico/icons'
import { minWidth, maxWidth } from '@emico/styles'
import { ButtonUnstyled, H2 } from '@emico/ui'
import { groupArrayByProperty } from '@emico/utils'

import { InformationItemsUnionType } from '../lib/customTypes'
import { opacityStates } from '../lib/transitionStates'
import { CraftInformationItemSubjectsStaticData } from '../lib/useCraftInformationItemSubjects'
import theme from '../theme'
import InformationSubjectItems from './InformationSubjectItems'

const Grid = styled.div`
  display: grid;
  padding-top: ${theme.spacing['2xl']};
  grid-row-gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${theme.spacing.xl};
  }
`

const Title = styled(H2)`
  @media ${minWidth('md')} {
    grid-column: span 5;
  }

  @media ${minWidth('lg')} {
    grid-column: span 4;
  }
`

const InformationSubject = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${minWidth('md')} {
    grid-column: 1 / span 5;
  }

  @media ${minWidth('lg')} {
    grid-column: 1 / span 4;
  }
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: 14px;

  @media ${maxWidth('sm')} {
    transform: rotate(90deg);
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.md};
  position: relative;
  width: 100%;
  background-color: ${({ isActive }) =>
    isActive ? 'rgba(215, 25, 32, 0.1)' : theme.colors.grayLight};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  &:hover {
    background-color: rgba(215, 25, 32, 0.1);
  }

  &::before {
    content: '';
    width: 4px;
    height: ${({ isActive }) => (isActive ? '100%' : 0)};
    background-color: ${theme.colors.primary};
    position: absolute;
    left: 0;
    top: 0;
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.fast};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }

  ${StyledChevronRightIcon} {
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.slow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
    color: ${({ isActive }) => isActive && theme.colors.primary};
  }
`

const InformationSubjectGroups = styled.div`
  position: relative;
  display: grid;
  margin-top: ${theme.spacing.md};

  @media ${minWidth('md')} {
    grid-column: span 7;
    margin-top: 0;
  }

  @media ${minWidth('lg')} {
    grid-column: span 8;
  }
`

const InformationSubjectGroup = styled('div', {
  shouldForwardProp: (prop) =>
    !['state', 'isActive', 'height'].includes(prop.toString()),
})<{ state: TransitionStatus; isActive: boolean }>`
  opacity: ${({ state }) => opacityStates[state]};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  z-index: ${({ isActive }) => isActive && 1};
  grid-row: 1;
  grid-column: 1;
`

type InformationSubjectsType = Exclude<
  CraftInformationItemSubjectsStaticData['craftDataInformationItemSubjects'],
  null
>

interface InformationQuery {
  itemSubject?: string
}

interface Props {
  subjects?: InformationSubjectsType
  items: InformationItemsUnionType
}

const InformationItemsShort = ({ subjects, items }: Props) => {
  const nodeRef = useRef(null)
  const router = useRouter()
  const { itemSubject }: InformationQuery = router.query

  // Group all information items by information subject
  const groupedInformationItems = groupArrayByProperty(items, (item) =>
    item?.informationItemShort?.[0]?.informationSubject?.[0]?.__typename ===
    'CraftInformationItemSubjectEntry'
      ? item?.informationItemShort?.[0]?.informationSubject?.[0].title ?? ''
      : '',
  )

  // Only show information subjects with 1 or more information items linked to them
  const filteredSubjects = subjects?.filter(
    (subject) =>
      subject?.__typename === 'CraftInformationItemSubjectEntry' &&
      groupedInformationItems.find(
        (item) =>
          item.groupName === subject.title && item.groupItems.length !== 0,
      ),
  )

  // activeInformationSubject filter is either itemSubject passed by routerQuery
  // or first of subject list when no routerQuery exists
  const firstSubject =
    itemSubject ??
    (filteredSubjects?.[0]?.__typename === 'CraftInformationItemSubjectEntry'
      ? filteredSubjects?.[0].title
      : null)

  const [activeInformationSubject, setActiveInformationSubject] =
    useState<Maybe<string> | null>(firstSubject)

  useEffect(() => {
    if (itemSubject) {
      setActiveInformationSubject(itemSubject)
    }
  }, [itemSubject])

  if (filteredSubjects?.length === 0 || groupedInformationItems?.length === 0) {
    return null
  }

  return (
    <Grid>
      <Title>
        <Trans>Choose a topic you have a question about</Trans>
      </Title>

      <InformationSubject>
        {filteredSubjects?.map(
          (subject) =>
            subject?.__typename === 'CraftInformationItemSubjectEntry' && (
              <li key={subject.title}>
                <StyledButtonUnstyled
                  analyticsContext="information.items"
                  analyticsName={`subject-${subject.title ?? ''}`}
                  onClick={() => setActiveInformationSubject(subject.title)}
                  isActive={activeInformationSubject === subject.title}
                >
                  {subject.title}

                  <span>
                    <StyledChevronRightIcon />
                  </span>
                </StyledButtonUnstyled>
              </li>
            ),
        )}
      </InformationSubject>

      <InformationSubjectGroups>
        {groupedInformationItems.map((group) => {
          const isGroupActive = group.groupName === activeInformationSubject

          return (
            <Transition
              nodeRef={nodeRef}
              key={group.groupName}
              in={isGroupActive}
              timeout={800}
            >
              {(state: TransitionStatus) => (
                <InformationSubjectGroup state={state} isActive={isGroupActive}>
                  <InformationSubjectItems subjectItems={group.groupItems} />
                </InformationSubjectGroup>
              )}
            </Transition>
          )
        })}
      </InformationSubjectGroups>
    </Grid>
  )
}

export default InformationItemsShort
