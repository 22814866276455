import { t } from '@lingui/macro'
import React from 'react'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Container from '../../../../components/Container'
import InformationItemsShort from '../../../../components/InformationItemsShort'
import Meta from '../../../../components/Meta'
import MetaCanonical from '../../../../components/MetaCanonical'
import PageHeader from '../../../../components/PageHeader'
import { InformationItemsUnionType } from '../../../../lib/customTypes'
import getSeoPageDescription from '../../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../../lib/getSeoPageTitle'
import { CraftInformationSubjectStaticData } from './useInformationSubject'

type Props = CraftInformationSubjectStaticData

const InformationSubject = ({
  craftDataInformationSubject,
  craftDataInformationSubjectItems: items,
  craftDataInformationItemSubjects: subjects,
}: Props) => {
  const subject =
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectGoCartsEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectTrampolinesEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectRideOnCarsEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectBalanceBikesEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectScootersEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectPlaygroundEquipmentEntry'
      ? craftDataInformationSubject
      : undefined

  const headerImage = subject?.image?.[0]

  const breadcrumbName = {
    name: subject?.title ?? undefined,
  }

  return (
    <>
      <Meta
        metaTitle={
          subject?.metaTitle ??
          getSeoPageTitle(t({ message: `Information | ${subject?.title}` }))
        }
        metaDescription={subject?.metaDescription ?? getSeoPageDescription()}
      />

      <MetaCanonical />

      <section>
        {subject?.title && headerImage && (
          <PageHeader
            title={subject.title}
            subtitle={subject.subtitle ?? undefined}
            image={headerImage}
          />
        )}

        <Container>
          <Breadcrumbs customName={breadcrumbName} />

          {items?.length !== 0 && (
            <InformationItemsShort
              subjects={subjects}
              items={items as InformationItemsUnionType}
            />
          )}
        </Container>
      </section>
    </>
  )
}

export default InformationSubject
