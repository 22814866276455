import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback, useState } from 'react'

import {
  CreatePlateMutation,
  CreatePlateMutationVariables,
} from './useCreatePlate.generated'

export interface PlateInputFormValues {
  uniqueCode: string
  plateType: string
  plateText: string
  firstname: string
  lastname: string
  street: string
  houseNumber: string
  city: string
  postalCode: string
  country: string
  email: string
}

const createPlateMutation = gql`
  mutation CreatePlate($plate: PlateInput!) {
    createPlate(plate: $plate) {
      plateId
    }
  }
` as TypedDocumentNode<CreatePlateMutation, CreatePlateMutationVariables>

/**
 * Mutation to create a license plate
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const { submitPlate, plateIsCreated } = useCreatePlate()
 *
 *  const onSubmit = () => {
 *    submitCode(plate)
 *  }

 * }
 * ```
 */

export function useCreatePlate() {
  const client = useApolloClient()
  const authorizationContext = useAuthorizationContext()
  const [plateIsCreated, setPlateIsCreated] = useState(false)
  const [hasError, setHasError] = useState(false)

  const submitPlate = useCallback(
    /**
     *
     * @param plate The plate that needs to be registered
     */
    async (plate: PlateInputFormValues) => {
      const {
        uniqueCode,
        plateType,
        plateText,
        firstname,
        lastname,
        street,
        houseNumber,
        city,
        postalCode,
        country,
        email,
      } = plate

      try {
        setPlateIsCreated(false)
        setHasError(false)

        const { data } = await client.mutate({
          mutation: createPlateMutation,
          variables: {
            plate: {
              unique_code: uniqueCode,
              plate_type: plateType,
              plate_text: plateText,
              address_1: street,
              address_2: houseNumber,
              postal_code: postalCode,
              firstname,
              lastname,
              city,
              country,
              email,
            },
          },
          context: authorizationContext,
        })

        if (data?.createPlate?.plateId) {
          setPlateIsCreated(true)
        }
      } catch (error) {
        setHasError(true)
      }
    },
    [authorizationContext, client],
  )

  return {
    submitPlate,
    hasError,
    plateIsCreated,
  }
}
