import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import NextError from 'next/error'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import { CraftCustomerServiceOverviewStaticData } from '../pages/craft/customer-service/useCustomerServiceOverview'
import theme from '../theme'
import CraftImage from './CraftImage'
import NextLink from './NextLink'
import SectionHeader, { StyledSectionTitle } from './SectionHeader'

const StyledSectionHeader = styled(SectionHeader)`
  ${StyledSectionTitle} {
    @media ${minWidth('lg')} {
      font-size: ${theme.fontSizes['2xl']};
    }
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const GridList = styled(List)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const SubjectItemList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Subject = styled.div`
  padding: ${theme.spacing.lg};
  height: 100%;
  border: ${theme.borders.default};
`

const SubjectHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.lg};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: ${theme.borders.default};
`

const SubjectTitle = styled(H3)`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledCraftImage = styled(CraftImage)`
  margin-right: ${theme.spacing.md};
  height: 25px;
  width: 25px;
  object-fit: contain;
`

const SubjectItem = styled.li`
  margin-bottom: ${theme.spacing.md};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledNextLink = styled(NextLink)`
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const ShowAllLink = styled(StyledNextLink)`
  font-weight: ${theme.fontWeights.medium};
`

type Props = CraftCustomerServiceOverviewStaticData

const CustomerServiceSubjectsMenu = ({
  craftDataCustomerServiceOverview: overview,
  craftDataCustomerServiceSubjects: subjects,
  ...other
}: Props) => {
  if (overview?.__typename !== 'CraftCustomerServiceOverviewEntry') {
    return <NextError statusCode={404} />
  }

  const sortedSubjects = sortArrayByProperty(
    subjects ?? [],
    (subject) => subject?.__typename === 'CraftFaqPageEntry' && subject.order,
  )

  return (
    <div {...other}>
      <StyledSectionHeader title={<Trans>Help subjects</Trans>} />

      <nav>
        <GridList>
          {sortedSubjects?.map((subject) => {
            if (subject?.__typename !== 'CraftFaqPageEntry') {
              return null
            }

            const totalSubjectItemsCount = subject.faqItems?.length ?? 0
            const visibleSubjectItems = subject.faqItems?.slice(0, 3)
            const subjectLogo = subject.logo?.[0]

            return (
              <li key={subject.uid}>
                <Subject>
                  <SubjectHeader>
                    {subjectLogo && (
                      <StyledCraftImage
                        image={subjectLogo}
                        width={25}
                        height={25}
                      />
                    )}

                    <SubjectTitle>{subject.title}</SubjectTitle>
                  </SubjectHeader>

                  <SubjectItemList>
                    {visibleSubjectItems?.map((item) => (
                      <SubjectItem key={item?.urlKey}>
                        <StyledNextLink
                          analyticsContext="customerService.overview"
                          analyticsName={`subject.item.${item?.faqTitle}`}
                          href={{
                            pathname: `/${overview.slug}/${subject.slug}`,
                            query: {
                              subject: item?.urlKey,
                            },
                          }}
                        >
                          {item?.faqTitle}
                        </StyledNextLink>
                      </SubjectItem>
                    ))}

                    {totalSubjectItemsCount > 3 && (
                      <SubjectItem>
                        <ShowAllLink
                          analyticsContext="customerService.overview"
                          analyticsName={`showAll.${subject.title}`}
                          href={`/${overview.slug}/${subject.slug}`}
                        >
                          <Trans>Show all</Trans>
                        </ShowAllLink>
                      </SubjectItem>
                    )}
                  </SubjectItemList>
                </Subject>
              </li>
            )
          })}
        </GridList>
      </nav>
    </div>
  )
}

export default CustomerServiceSubjectsMenu
