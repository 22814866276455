import { t } from '@lingui/macro'
import React from 'react'
import { Control, FieldValues, Path, UseFormRegister } from 'react-hook-form'

import { emailPattern } from '@emico/input'

import Input from './Input'

type Scope = 'default' | 'shipping' | 'billing'

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  control: Control<T>
  scope?: Scope
  label?: string
  registerName?: string
  autoFocus?: boolean
}

const FormFieldsEmail = <T extends FieldValues>({
  register,
  control,
  label,
  registerName = 'email',
  autoFocus = false,
}: Props<T>) => {
  const errorMessages = {
    required: {
      email: t({
        message: 'Enter your email address',
      }),
    },
    pattern: {
      email: t({
        message:
          'Enter an email address in the correct format. For example: name@example.com',
      }),
    },
  }

  return (
    <Input
      control={control}
      autoFocus={autoFocus}
      label={
        label ??
        t({
          message: 'Email address',
        })
      }
      type="email"
      {...register(registerName as Path<T>, {
        // Show input error when input has no value on submit
        required: {
          value: true,
          message: errorMessages.required.email,
        },
        // Show input error when input doesn't meet the required email pattern
        pattern: {
          value: emailPattern,
          message: errorMessages.pattern.email,
        },
      })}
    />
  )
}

export default FormFieldsEmail
