import React, { SVGProps } from 'react'

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 17"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M11.2 16.25h-.082C1.696 15.544.358 5.181.172 2.019a2.414 2.414 0 01.065-.728C.296 1.056.39.839.512.65.635.464.785.31.954.198c.168-.112.352-.179.54-.198h2.643c.192 0 .38.075.539.215.159.14.282.34.353.573l.728 2.337c.07.227.088.476.05.716a1.4 1.4 0 01-.26.634L4.524 5.819c.16 1.181.594 2.27 1.244 3.12.65.85 1.484 1.419 2.39 1.63L9.2 9.225a.91.91 0 01.49-.332.755.755 0 01.55.076l1.808.944c.176.095.326.257.43.464.106.207.161.45.16.698v3.3c0 .497-.152.974-.421 1.326-.27.351-.636.549-1.018.549zm-9.59-15a.431.431 0 00-.34.183.732.732 0 00-.14.442v.05c.221 3.7 1.636 12.45 10.04 13.075a.377.377 0 00.187-.033.47.47 0 00.161-.123.639.639 0 00.114-.195.782.782 0 00.047-.236v-3.338l-1.808-.944-1.376 1.781-.23-.037C4.094 11.194 3.528 5.756 3.528 5.7l-.029-.3 1.362-1.794-.72-2.356H1.61z"
      fill="currentColor"
    />
  </svg>
)

export default PhoneIcon
