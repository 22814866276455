import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import NextError from 'next/error'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import { PlateInputFormValues, useCreatePlate } from '../lib/useCreatePlate'
import { RequestLicensePlateQuery } from '../pages/craft/request-license-plate/useRequestLicensePlate.generated'
import theme from '../theme'
import PlateTypeOptions from './CreatePlateTypeForm'
import FormFieldPlateText from './FormFieldPlateText'
import FormFieldsAddress from './FormFieldsAddress'
import FormFieldsEmail from './FormFieldsEmail'
import FormFieldsName from './FormFieldsName'
import HtmlContent from './HtmlContent'
import { StyledButtonPrimary } from './LoginForm'

const StyledH3 = styled(H3)`
  margin-top: ${theme.spacing['2xl']};
  margin-bottom: ${`calc(${theme.spacing.md} * -1)`};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;

  @media ${minWidth('md')} {
    margin-bottom: 0;
  }
`

const Form = styled.form`
  display: grid;
  gap: ${theme.spacing.lg};
  margin: ${theme.spacing.lg} 0 ${theme.spacing.xl} 0;

  @media ${minWidth('md')} {
    gap: ${theme.spacing.sm};
  }
`

const Fieldset = styled.fieldset`
  margin-bottom: 0;

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.sm};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const FormGrid = styled.div`
  display: grid;
  grid-column: 1 / 1;
  gap: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ErrorText = styled.p`
  color: ${theme.colors.primary};
`

interface Props {
  craftDataRequestLicensePlate: Exclude<RequestLicensePlateQuery['entry'], null>
  uniqueCode: string
  onPlateIsCreated: () => void
}

const CreatePlateForm = ({
  craftDataRequestLicensePlate,
  uniqueCode,
  onPlateIsCreated,
}: Props) => {
  const { submitPlate, hasError, plateIsCreated } = useCreatePlate()
  const { handleSubmit, control, register, setValue, setFocus } =
    useForm<PlateInputFormValues>({ mode: 'onBlur' })
  const [showError, setShowError] = useState(false)

  const onSubmit = (plate: PlateInputFormValues) => {
    submitPlate({ ...plate, uniqueCode })
  }

  useEffect(() => {
    if (hasError) {
      setShowError(true)
    }
  }, [hasError, showError])

  useEffect(() => {
    if (plateIsCreated) {
      onPlateIsCreated()
    }
  }, [onPlateIsCreated, plateIsCreated])

  if (
    craftDataRequestLicensePlate?.__typename !== 'CraftRequestLicensePlateEntry'
  ) {
    return <NextError statusCode={404} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledH3>
        <Trans>Select license plate</Trans>
      </StyledH3>
      <PlateTypeOptions register={register} />

      <StyledH3>
        <Trans>Choose license plate inscription</Trans>
      </StyledH3>
      {craftDataRequestLicensePlate.inscriptionText ? (
        <HtmlContent html={craftDataRequestLicensePlate.inscriptionText} />
      ) : null}
      <FormGrid>
        <Fieldset>
          <FormFieldPlateText control={control} />
        </Fieldset>
      </FormGrid>

      <StyledH3>
        <Trans>Delivery details</Trans>
      </StyledH3>
      <p>
        <Trans>Where should we ship the license plate to?</Trans>
      </p>
      <Fieldset>
        <FormFieldsName register={register} control={control} />
      </Fieldset>

      <Fieldset>
        <FormFieldsEmail register={register} control={control} />
      </Fieldset>

      <Fieldset>
        <FormFieldsAddress
          control={control}
          register={register}
          setValue={setValue}
          setFocus={setFocus}
          showAllCountries
          isCountryDisabled={false}
        />
      </Fieldset>

      <StyledButtonPrimary
        analyticsContext="request.license.plate"
        analyticsName="create.plate"
        colorType="neutral"
      >
        <Trans>Request license plate</Trans>
      </StyledButtonPrimary>
      {hasError && showError ? (
        <ErrorText>
          <Trans>Could not create license plate, please try again later</Trans>
        </ErrorText>
      ) : null}
    </Form>
  )
}

export default CreatePlateForm
