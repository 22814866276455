import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import {
  ExceptionCategories,
  getMagentoErrorCategory,
} from '@emico-utils/magento-graphql-error-categories'
import { useCallback, useState } from 'react'

import { CodeStatus } from '@emico/graphql-schema-types/src'

import { GetCodeQuery, GetCodeQueryVariables } from './useCodeStatus.generated'

const query = gql`
  query GetCode($uniqueCode: String) {
    getCode(uniqueCode: $uniqueCode) {
      status
    }
  }
` as TypedDocumentNode<GetCodeQuery, GetCodeQueryVariables>

/**
 * Query to check the status of a unique code that can be used to request a license plate
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const {submitCode, isValid} = useCodeStatus()
 *
 *  const onSubmit = () => {
 *    submitCode(code)
 *  }
 * }
 * ```
 */

export function useCodeStatus() {
  const client = useApolloClient()
  const authorizationContext = useAuthorizationContext()
  const [hasError, setHasError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [isUsed, setIsUsed] = useState(false)

  const handleCodeStatus = (status?: CodeStatus) => {
    if (status === CodeStatus.NEW) {
      setIsValid(true)
    }

    if (status && [CodeStatus.USED, CodeStatus.EXPORTED].includes(status)) {
      setIsUsed(true)
    }
  }

  const handleError = (error: unknown) => {
    const category = getMagentoErrorCategory(error)

    if (category === ExceptionCategories.NO_SUCH_ENTITY) {
      setIsInvalid(true)
    } else {
      setHasError(true)
    }
  }

  const submitCode = useCallback(
    /**
     *
     * @param uniqueCode A unique code to request a license plate
     */
    async (uniqueCode: string) => {
      try {
        setHasError(false)
        setIsValid(false)
        setIsInvalid(false)
        setIsUsed(false)

        const { data } = await client.query({
          query,
          variables: {
            uniqueCode,
          },
          context: authorizationContext,
        })

        handleCodeStatus(data.getCode?.status)
      } catch (error) {
        handleError(error)
      }
    },
    [authorizationContext, client],
  )

  return {
    submitCode,
    hasError,
    isValid,
    isInvalid,
    isUsed,
  }
}
