import { gql, TypedDocumentNode, useQuery } from '@apollo/client'

import { CraftStoreEntryFragment } from '../pages/craft/store-locator/useStoreLocator'
import {
  StoresByDistanceQuery,
  StoresByDistanceQueryVariables,
} from './useStoresByDistance.generated'

const query = gql`
  query StoresByDistance($lat: Float!, $lng: Float!, $site: String!) {
    storesByDistance(
      referenceLatitude: $lat
      referenceLongitude: $lng
      limit: 20
      offset: 0
      site: $site
    ) {
      ...CraftStoreEntryFragment
    }
  }

  ${CraftStoreEntryFragment}
` as TypedDocumentNode<StoresByDistanceQuery, StoresByDistanceQueryVariables>

export function useStoresByDistance(
  lat: number | null,
  lng: number | null,
  site: string,
) {
  const { data } = useQuery(query, {
    variables: {
      lat: lat ?? 0,
      lng: lng ?? 0,
      site,
    },
    skip: lat === null || lng === null,
  })

  return data
}
