import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Container from '../../../../components/Container'
import CraftPageBlocks from '../../../../components/CraftPageBlocks'
import InformationSubjectItems from '../../../../components/InformationSubjectItems'
import Meta from '../../../../components/Meta'
import MetaCanonical from '../../../../components/MetaCanonical'
import PageHeader from '../../../../components/PageHeader'
import { InformationItemsUnionType } from '../../../../lib/customTypes'
import getSeoPageDescription from '../../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../../lib/getSeoPageTitle'
import unslugify from '../../../../lib/unslugify'
import theme from '../../../../theme'
import { CraftInformationDetailStaticData } from './useInformationDetail'

const PageBlocksWrapper = styled.div`
  padding-top: ${theme.spacing.xl};
  margin-bottom: ${theme.spacing['2xl']};

  @media ${minWidth('md')} {
    padding-top: ${theme.spacing['2xl']};
  }

  @media ${minWidth('md')} {
    padding-top: ${theme.spacing['3xl']};
  }
`

type Props = CraftInformationDetailStaticData

const Information = ({
  craftDataInformationSubject,
  craftDataInformationDetail,
  craftDataInformationSubjectItems: items,
  resolvedRoute,
}: Props) => {
  const subject =
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectGoCartsEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectTrampolinesEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectRideOnCarsEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectBalanceBikesEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectScootersEntry' ||
    craftDataInformationSubject.__typename ===
      'CraftInformationSubjectPlaygroundEquipmentEntry'
      ? craftDataInformationSubject
      : undefined

  const detail =
    craftDataInformationDetail.__typename === 'CraftInformationGoCartsEntry' ||
    craftDataInformationDetail.__typename ===
      'CraftInformationTrampolinesEntry' ||
    craftDataInformationDetail.__typename ===
      'CraftInformationRideOnCarsEntry' ||
    craftDataInformationDetail.__typename ===
      'CraftInformationBalanceBikesEntry' ||
    craftDataInformationDetail.__typename === 'CraftInformationScootersEntry' ||
    craftDataInformationDetail.__typename ===
      'CraftInformationPlaygroundEquipmentEntry'
      ? craftDataInformationDetail
      : undefined

  const relatedItems = items
    ?.filter((item) => item && 'uid' in item && item.uid !== detail?.uid)
    .slice(0, 10)

  const showRelatedItems =
    detail?.showRelatedQuestions && relatedItems?.length !== 0
  const headerImage = detail?.image?.[0]

  const parentInformationSlug =
    resolvedRoute?.relativeUrl?.split('/').slice(0, 1).join('/') ?? ''

  const parentSubjectSlug =
    resolvedRoute?.relativeUrl?.split('/').slice(0, 2).join('/') ?? ''

  const breadcrumbParents = [
    {
      name: unslugify(parentInformationSlug),
      url: parentInformationSlug,
    },
    {
      name: subject?.title ?? '',
      url: parentSubjectSlug,
    },
  ]

  return (
    <>
      <Meta
        metaTitle={
          detail?.metaTitle ??
          getSeoPageTitle(t({ message: `Information | ${subject?.title}` }))
        }
        metaDescription={detail?.metaDescription ?? getSeoPageDescription()}
      />

      <MetaCanonical />

      <section>
        {detail?.title && headerImage && (
          <PageHeader title={detail.title} image={headerImage} hasSmallTitle />
        )}

        <Container>
          <Breadcrumbs customParents={breadcrumbParents} />
        </Container>

        {detail && detail?.pageBlocks.length !== 0 && (
          <PageBlocksWrapper>
            <CraftPageBlocks blocks={detail.pageBlocks} />
          </PageBlocksWrapper>
        )}

        {showRelatedItems && (
          <Container>
            <InformationSubjectItems
              subjectItems={relatedItems as InformationItemsUnionType}
              title={<Trans>Related questions</Trans>}
            />
          </Container>
        )}
      </section>
    </>
  )
}

export default Information
