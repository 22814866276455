import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { maxWidth, minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import { CraftImageType } from '../lib/customTypes'
import getDate from '../lib/getDate'
import theme from '../theme'
import CraftImage from './CraftImage'
import HtmlContent from './HtmlContent'
import NextLink from './NextLink'

const Figure = styled.figure`
  margin: 0;
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
  object-fit: cover;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  @media ${maxWidth('md')} {
    height: 183px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  background-color: ${theme.colors.backgroundLight};
  padding: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    padding: ${theme.spacing['2xl']};
  }
`

const Title = styled(H3)`
  margin-bottom: ${theme.spacing.md};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes.xl};
  }
`

const subtitleStyling = css`
  margin: ${theme.spacing.xxs} 0 0;
  font-size: ${theme.fontSizes.md};
  text-transform: uppercase;

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes.lg};
  }
`

const Subtitle = styled.p`
  ${subtitleStyling};
`

const Time = styled.time`
  ${subtitleStyling};
`

const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => !['readMoreLines'].includes(prop.toString()),
})<{ readMoreLines?: number }>`
  ${({ readMoreLines }) =>
    readMoreLines && theme.stylingSnippet.lineClamp(readMoreLines)};

  &:not(:last-child) {
    margin-bottom: ${theme.spacing.lg};
  }
`

const StyledNextLink = styled(NextLink)`
  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(
      1.05,
      theme.transition.durations.extraSlow,
    )};
  }
`

const StyledNextLinkText = styled(NextLink)`
  margin-top: auto;
  border-bottom: ${theme.borders.md}
    var(--link-hover-color, ${theme.colors.primary});
  color: ${theme.colors.text};
  font-weight: ${theme.fontWeights.bold};
  text-decoration: none;

  &::before {
    bottom: -5px;
  }

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }
`

const Article = styled.article`
  display: flex;
  flex-direction: column;

  @media ${minWidth('lg')} {
    &:has(${StyledNextLinkText}:hover) {
      ${StyledCraftImage} {
        transform: scale(1.05);
      }
    }
  }
`

interface Props {
  /**
   * Card title
   */
  title: string
  /**
   * Card's image
   */
  image: CraftImageType
  /**
   * Rich text to show in card
   */
  richText: string
  /**
   * Subtitle to show above the title
   */
  subtitle?: string
  /**
   * Date to show above the title
   */
  date?: string
  /**
   * Number of text lines that should be visible.
   * If the text exceeds the number of lines, it will be cut short with ellipsis.
   */
  readMoreLines?: number
  /**
   * Custom text for the card's link.
   * Default is 'Read more'.
   */
  readMoreText?: string
  /**
   * URL for card's optional link.
   */
  url?: string
}

const InformationCard = ({
  title,
  subtitle,
  date,
  image,
  richText,
  readMoreLines,
  readMoreText,
  url,
}: Props) => {
  const dateReadable = date ? getDate(date) : undefined

  return (
    <Article>
      <StyledNextLink
        analyticsContext="information.card"
        analyticsName={title}
        href={`${url}`}
      >
        <Figure>
          <StyledCraftImage
            image={image}
            sizes={theme.imageSizes.productCard.sizes}
            height={215}
          />
        </Figure>
      </StyledNextLink>

      <Content>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        {date && <Time dateTime={date}>{dateReadable}</Time>}

        <Title>{title}</Title>

        <TextWrapper readMoreLines={readMoreLines}>
          <HtmlContent html={richText} />
        </TextWrapper>

        {url && (
          <StyledNextLinkText
            analyticsContext="information.card"
            analyticsName={title}
            href={`${url}`}
          >
            {readMoreText ?? <Trans>Read more</Trans>}
          </StyledNextLinkText>
        )}
      </Content>
    </Article>
  )
}

export default InformationCard
