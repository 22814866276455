/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

import theme from '../theme'
import { PlateType } from './CreatePlateTypeForm'
import NextImage from './NextImage'
import RadioCustom from './RadioCustom'

const Option = styled('div', {
  shouldForwardProp: (prop) => !['isSelected'].includes(prop.toString()),
})<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.grayMiddle : theme.colors.grayLight};
  border-bottom: 3px solid;
  border-bottom-color: ${({ isSelected }) =>
    isSelected ? theme.colors.green : 'transparent'};
`

const StyledRadioCustom = styled(RadioCustom as any)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.lg};
  cursor: pointer;
`

const Text = styled.span`
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

interface Props<T extends FieldValues> {
  plateType: PlateType
  register: UseFormRegister<T>
}

const CreatePlateTypeOption = <T extends FieldValues>({
  plateType,
  register,
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const { name, code, image } = plateType

  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)
  }

  return (
    <Option isSelected={isSelected}>
      <StyledRadioCustom
        value={code}
        showButton={false}
        hasCustomSelectState={false}
        handleCustomRadioState={handleStateChange}
        {...register('plateType' as Path<T>, {
          required: true,
        })}
      >
        <NextImage
          src={image}
          alt={name}
          width={125}
          height={25}
          objectFit="contain"
        />

        <Text>{name}</Text>
      </StyledRadioCustom>
    </Option>
  )
}

export default CreatePlateTypeOption
