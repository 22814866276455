import { RadioGroup } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { StaticImageData } from 'next/image'
import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import plateAustria from '../images/plateAustria.png'
import plateBelgium from '../images/plateBelgium.png'
import plateFrance from '../images/plateFrance.png'
import plateGermany from '../images/plateGermany.png'
import plateGreatBritain from '../images/plateGreatBritain.png'
import plateIreland from '../images/plateIreland.png'
import plateNetherlands from '../images/plateNetherlands.png'
import plateWhite from '../images/plateWhite.png'
import plateYellow from '../images/plateYellow.png'
import theme from '../theme'
import CreatePlateTypeOption from './CreatePlateTypeOption'

const StyledRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.spacing.xs};

  margin-top: ${theme.spacing.sm};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export interface PlateType {
  name: string
  code: string
  image: StaticImageData
}

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
}

const PlateTypeOptions = <T extends FieldValues>({ register }: Props<T>) => {
  const plateTypes: PlateType[] = [
    {
      name: t({
        message: 'Netherlands',
      }),
      code: 'nl',
      image: plateNetherlands,
    },
    {
      name: t({
        message: 'Ireland',
      }),
      code: 'irland',
      image: plateIreland,
    },
    {
      name: t({
        message: 'Belgium',
      }),
      code: 'belgie',
      image: plateBelgium,
    },
    {
      name: t({
        message: 'France',
      }),
      code: 'france',
      image: plateFrance,
    },
    {
      name: t({
        message: 'Great Britain',
      }),
      code: 'gb',
      image: plateGreatBritain,
    },
    {
      name: t({
        message: 'Germany',
      }),
      code: 'german',
      image: plateGermany,
    },
    {
      name: t({
        message: 'Austria',
      }),
      code: 'austria',
      image: plateAustria,
    },
    {
      name: t({
        message: 'Blank Yellow',
      }),
      code: 'yellow',
      image: plateYellow,
    },
    {
      name: t({
        message: 'Blank White',
      }),
      code: 'white',
      image: plateWhite,
    },
  ]

  return (
    <StyledRadioGroup label="Plate type" showLabel={false}>
      {plateTypes.map((plateType) => (
        <CreatePlateTypeOption
          key={plateType.code}
          plateType={plateType}
          register={register}
        />
      ))}
    </StyledRadioGroup>
  )
}

export default PlateTypeOptions
