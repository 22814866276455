import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import { CraftInformationStaticData } from '../pages/craft/information/useInformation'
import theme from '../theme'
import NextLink from './NextLink'
import SectionHeader from './SectionHeader'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const StyledNextLink = styled(NextLink)`
  display: flex;
  margin-top: ${theme.spacing.md};
  padding-top: ${theme.spacing.md};
  border-top: ${theme.borders.default};
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover('currentColor', '1px', 'h3')};
  }
`

type Props = Pick<CraftInformationStaticData, 'craftDataInformationSubjects'>

const InformationSubjectMenuSimple = ({
  craftDataInformationSubjects: subjects,
  ...other
}: Props) => {
  const sortedSubjects = sortArrayByProperty(
    subjects ?? [],
    (subject) =>
      (subject?.__typename === 'CraftInformationSubjectGoCartsEntry' ||
        subject?.__typename === 'CraftInformationSubjectTrampolinesEntry' ||
        subject?.__typename === 'CraftInformationSubjectRideOnCarsEntry' ||
        subject?.__typename === 'CraftInformationSubjectBalanceBikesEntry' ||
        subject?.__typename === 'CraftInformationSubjectScootersEntry' ||
        subject?.__typename ===
          'CraftInformationSubjectPlaygroundEquipmentEntry') &&
      subject.order,
  )

  return (
    <div {...other}>
      <SectionHeader
        title={<Trans>Information subjects</Trans>}
        hasLargeTitle
      />

      <nav>
        <List>
          {sortedSubjects?.map((subject) => {
            if (
              subject?.__typename !== 'CraftInformationSubjectGoCartsEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectTrampolinesEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectRideOnCarsEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectBalanceBikesEntry' &&
              subject?.__typename !== 'CraftInformationSubjectScootersEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectPlaygroundEquipmentEntry'
            ) {
              return null
            }

            return (
              <li key={subject.uid}>
                <StyledNextLink
                  analyticsContext="information.subject.menu.simple"
                  analyticsName={`subject.${subject.title}`}
                  href={`/${subject.uri}`}
                >
                  <H3>{subject.title}</H3>
                </StyledNextLink>
              </li>
            )
          })}
        </List>
      </nav>
    </div>
  )
}

export default InformationSubjectMenuSimple
