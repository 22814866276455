import { useApolloClient } from '@apollo/client'
import { useCreateCart } from '@emico-hooks/cart-create'
import { useCartId, useCartIdContext } from '@emico-hooks/cart-id'
import { mergeCarts } from '@emico-hooks/cart-merge'
import {
  getCustomerTokenHeaderObject,
  useLoginTokenContext,
} from '@emico-hooks/login-token'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'

import type { LoginResponse } from '../pages/api/login.api'

/**
 * Login into the store using an email and password. The token will be stored in a cookie.
 * The refresh token will be stored in a cookie with the httpOnly flag set to true.
 * @param store The store code of the store to login into
 * @param email The email address of the customer
 * @param password The password of the customer
 */
function login(
  store: string,
  email: string,
  password: string,
): Promise<LoginResponse> {
  const headers = new Headers()

  headers.append('Content-Type', 'application/json')

  return fetch('/api/login', {
    headers,
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      store,
    }),
  }).then((res) => res.json())
}

/**
 * Hook to login into the active store
 * @returns A login function that can be used to login into the active store
 */
export function useLogin() {
  const apolloClient = useApolloClient()
  const store = useActiveStoreView()
  const loginTokenContext = useLoginTokenContext()
  const createCart = useCreateCart()
  const cartId = useCartId()
  const cartIdVar = useCartIdContext()

  return async (email: string, password: string) => {
    const result = await login(store.code, email, password)

    if ('error' in result) {
      throw new Error(result.error)
    }

    if (!('token' in result)) {
      throw new Error('Received no token')
    }

    const token = result.token
    const temporaryTokenContext = getCustomerTokenHeaderObject(token)
    const newCartId = await createCart(false, {
      context: temporaryTokenContext,
    })

    if (cartId) {
      // If the user already had a cart, he had a guest cart. Guest carts can
      // not be used by logged in users. When logging in we want to preserve the
      // cart, so we need to create a logged-in-cart, and merge the guest cart
      // into it.
      try {
        await mergeCarts(apolloClient, token, cartId, newCartId)
      } catch {
        // "Some products are out of stock" is a likely response, in which case
        // the cart will still be partially merged. Even if M2 has a fatal error
        // while merging, this should not block the login attempt.
        // TODO: Show the user that some products were removed from their cart
      }
    }

    loginTokenContext(result.token)
    cartIdVar(newCartId)

    return {
      result,
      cartId: newCartId,
    }
  }
}
