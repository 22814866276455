import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { useCallback, useEffect, useRef } from 'react'

import { CraftStoreEntryFragment } from '../pages/craft/store-locator/useStoreLocator.generated'
import theme from '../theme'
import NextLinkPrimary from './NextLinkPrimary'

const StoreCard = styled('div', {
  shouldForwardProp: (p) => p !== 'isSelected',
})<{ isSelected: boolean }>`
  padding: ${theme.spacing.lg} ${theme.spacing.md};
  width: 100%;
  cursor: pointer;
  border: ${theme.borders.default};
  border-radius: ${theme.borderRadius.base};
  position: relative;

  box-shadow: ${(props) =>
    props.isSelected ? `0 0 0 4px ${theme.colors.borderDark} inset` : 'none'};

  &:hover {
    background: ${theme.colors.background};
  }
`

const StoreLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.md};
`

const StoreTitle = styled.h1`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  word-break: keep-all;
`

const StoreInfoTextBlock = styled.div`
  margin-top: ${theme.spacing.sm};
  margin-bottom: 0;
  word-break: break-all;
`

const StoreInfoText = styled.p`
  margin: 0;
`

const StyledNextLinkPrimary = styled(NextLinkPrimary)`
  text-decoration: underline;
`

const StoreDistanceLabel = styled.div`
  min-width: 43px;
  padding-top: 3px;
  text-align: right;
`

interface Props {
  location: CraftStoreEntryFragment
  showDistanceLabel?: boolean
  calculateDistance?: (location: CraftStoreEntryFragment) => number
  className?: string
  isSelected: boolean
  onStoreClick?: (location: CraftStoreEntryFragment) => void
}

const StoreLocatorResultsListCard = ({
  location,
  showDistanceLabel,
  calculateDistance,
  className,
  isSelected,
  onStoreClick,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isSelected])

  const handleStoreClick = useCallback(() => {
    onStoreClick?.(location)
  }, [location, onStoreClick])

  return (
    <StoreCard ref={ref} className={className} isSelected={isSelected}>
      <StoreLinkWrapper onClick={handleStoreClick}>
        <Header>
          <StoreTitle>{location.title}</StoreTitle>
          {showDistanceLabel && calculateDistance ? (
            <StoreDistanceLabel>
              {`${calculateDistance(location)} km`}
            </StoreDistanceLabel>
          ) : null}
        </Header>
        <StoreInfoTextBlock>
          <StoreInfoText>{location.address1}</StoreInfoText>
          <StoreInfoText>
            {location.zipcode} {location.city}
          </StoreInfoText>
        </StoreInfoTextBlock>

        <StoreInfoTextBlock>
          {location.phoneNumber ? (
            <StoreInfoText>
              <Trans>Tel</Trans>: {location.phoneNumber}
            </StoreInfoText>
          ) : null}
          {location.email ? (
            <StoreInfoText>
              <Trans>E-mail</Trans>: {location.email}
            </StoreInfoText>
          ) : null}
        </StoreInfoTextBlock>
      </StoreLinkWrapper>
      <StoreInfoTextBlock>
        <StyledNextLinkPrimary
          href={location.uri ?? '/'}
          analyticsContext="storelocator"
          analyticsName={`view.store.details.${location.storeId}`}
          isBasic
        >
          <Trans>Store details</Trans>
        </StyledNextLinkPrimary>
      </StoreInfoTextBlock>
    </StoreCard>
  )
}

export default StoreLocatorResultsListCard
