import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import CustomerServiceContact from '../../../../components/CustomerServiceContact'
import CustomerServiceSubjectItems from '../../../../components/CustomerServiceSubjectItems'
import CustomerServiceSubjectsMenuSimple from '../../../../components/CustomerServiceSubjectsMenuSimple'
import Meta from '../../../../components/Meta'
import MetaCanonical from '../../../../components/MetaCanonical'
import PageHeader from '../../../../components/PageHeader'
import getSeoPageDescription from '../../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../../lib/getSeoPageTitle'
import theme from '../../../../theme'
import { CraftCustomerServiceSubjectStaticData } from './useCustomerServiceSubject'

const StyledContainer = styled(Container)`
  padding-bottom: ${theme.spacing['2xl']};
  padding-top: ${theme.spacing['2xl']};

  @media ${minWidth('lg')} {
    padding-bottom: ${theme.spacing['3xl']};
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: ${theme.spacing['2xl']};

  @media ${minWidth('md')} {
    grid-template-columns: 2fr 1fr;
    grid-column-gap: ${theme.spacing['3xl']};
    grid-row-gap: ${theme.spacing['2xl']};
  }
`

const StyledCustomerServiceContact = styled(CustomerServiceContact)`
  @media ${minWidth('md')} {
    grid-column: 1 / 3;
  }

  @media ${minWidth('lg')} {
    grid-column: 1;
  }
`

type Props = CraftCustomerServiceSubjectStaticData

const CustomerServiceSubject = ({
  craftDataCustomerServiceSubject,
  craftDataCustomerServiceOverview: overview,
  craftDataCustomerServiceSubjects: subjects,
}: Props) => {
  const subject =
    craftDataCustomerServiceSubject.__typename === 'CraftFaqPageEntry'
      ? craftDataCustomerServiceSubject
      : undefined

  const headerImage = subject?.image?.[0]
  const subjectItemsCount = subject?.faqItems?.length ?? 0

  return (
    <>
      <Meta
        metaTitle={
          subject?.metaTitle ??
          getSeoPageTitle(
            t({ message: `Customer service | ${subject?.title}` }),
          )
        }
        metaDescription={subject?.metaDescription ?? getSeoPageDescription()}
      />

      <MetaCanonical />

      <section>
        {subject?.title && headerImage && (
          <PageHeader
            title={subject.title}
            image={headerImage}
            isLow
            hasSmallTitle
          />
        )}

        <Container>
          <Breadcrumbs />
        </Container>

        <StyledContainer>
          <Grid>
            <CustomerServiceSubjectItems
              title={
                <Trans>{subjectItemsCount} frequently asked questions</Trans>
              }
              subjectItems={subject?.faqItems ?? []}
            />

            <CustomerServiceSubjectsMenuSimple
              craftDataCustomerServiceOverview={overview}
              craftDataCustomerServiceSubjects={subjects}
              excludedSubject={subject}
            />

            <StyledCustomerServiceContact
              craftDataCustomerServiceOverview={overview}
            />
          </Grid>
        </StyledContainer>
      </section>
    </>
  )
}

export default CustomerServiceSubject
