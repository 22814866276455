/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import React, { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { emailPattern } from '@emico/input'
import { minWidth } from '@emico/styles'

import EyeIcon from '../icons/EyeIcon'
import EyeSlashIcon from '../icons/EyeSlashIcon'
import { getEmailErrorMessages } from '../lib/formErrorMessages'
import theme from '../theme'
import Alert from './Alert'
import ButtonPrimary, { ButtonColorType } from './ButtonPrimary'
import Input from './Input'
import NextLinkPrimary from './NextLinkPrimary'
import { useLogin } from './useLogin'

interface FormValues {
  email: string
  password: string
}

const StyledAlert = styled(Alert)`
  margin-bottom: ${theme.spacing.lg};
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledNextLinkPrimary = styled(NextLinkPrimary)`
  color: ${theme.colors.grayBrown};
`

export const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: ${theme.spacing['2xl']};
  width: 100%;

  @media ${minWidth('lg')} {
    width: auto;
  }
`

const StyledInput = styled(Input as any)`
  margin: ${theme.spacing.md} 0 ${theme.spacing.sm};

  @media ${minWidth('lg')} {
    margin: ${theme.spacing.lg} 0 ${theme.spacing.sm};
  }
` as typeof Input

interface Props {
  flow: string | string[] | undefined
  initialValues?: FieldValues
  submitButtonColorType?: ButtonColorType
}

const LoginForm = ({
  flow,
  initialValues,
  submitButtonColorType = 'brand',
  ...other
}: Props) => {
  const { handleSubmit, register, control } = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
  })
  const login = useLogin()
  const [hasError, setHasError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const hasInitialEmailValue = Boolean(initialValues?.email)

  const passwordErrorMessages = {
    required: t({
      message: 'Enter your password',
    }),
  }

  const onLogin = async ({ email, password }: FormValues) => {
    try {
      setHasError(false)
      await login(email, password)
    } catch (error) {
      console.error(error)
      setHasError(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onLogin)} {...other}>
      <StyledAlert type="error">
        {hasError && (
          <Trans>
            The combination of email address and password is incorrect or your
            account is disabled temporarily.
          </Trans>
        )}
      </StyledAlert>

      <Input
        autoFocus={!hasInitialEmailValue}
        control={control}
        label={t({
          message: 'Email address',
        })}
        type="email"
        {...register('email', {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: getEmailErrorMessages('required'),
          },
          // Show input error when input doesn't meet the required email pattern
          pattern: {
            value: emailPattern,
            message: getEmailErrorMessages('pattern'),
          },
        })}
      />

      <StyledInput
        autoFocus={hasInitialEmailValue}
        control={control}
        type={showPassword ? 'text' : 'password'}
        label={t`Password`}
        append={
          <ButtonUnstyled
            analyticsContext="login.form"
            analyticsName="show.password"
            onPress={() => setShowPassword((showPassword) => !showPassword)}
          >
            {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </ButtonUnstyled>
        }
        {...register('password', {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: passwordErrorMessages.required,
          },
        })}
      />

      <StyledNextLinkPrimary
        analyticsContext="login.form"
        analyticsName="forgot.password"
        isBasic
        href={{
          pathname: '/account/forgot-password',
          ...(flow && {
            query: {
              flow,
            },
          }),
        }}
      >
        <Trans>Forgot password?</Trans>
      </StyledNextLinkPrimary>

      <Buttons>
        <StyledButtonPrimary
          analyticsContext="login.form"
          analyticsName="login"
          type="submit"
          colorType={submitButtonColorType}
        >
          <Trans>Login</Trans>
        </StyledButtonPrimary>
      </Buttons>
    </form>
  )
}

export default LoginForm
