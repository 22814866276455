import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import dynamic from 'next/dynamic'
import React, { useCallback, useState } from 'react'

import { minWidth } from '@emico/styles'
import { H1 } from '@emico/ui'
import { stripMaybes } from '@emico/utils'

import Container from '../../../components/Container'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import StoreLocatorSearch from '../../../components/StoreLocatorSearch'
import { useStoresByDistance } from '../../../components/useStoresByDistance'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../lib/getSeoPageTitle'
import theme from '../../../theme'
import { CraftStoreLocatorStaticData } from './useStoreLocator'
import { CraftStoreEntryFragment } from './useStoreLocator.generated'

const StoreLocatorMap = dynamic(
  () => import('../../../components/StoreLocatorMap'),
)

const Bar = styled.div`
  background-color: ${theme.colors.grayLight};
  padding: ${theme.spacing.md};
  color: ${theme.colors.primary};

  @media ${minWidth('md')} {
    text-align: center;
  }
`

const StoreLocatorTitle = styled(H1)`
  margin-top: ${theme.pagePadding};
  font-size: ${theme.fontSizes.lg};

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: ${theme.spacing.md};
  flex-direction: column-reverse;

  @media ${minWidth('sm')} {
    flex-direction: row;
  }

  @media ${minWidth('lg')} {
    display: flex;
    width: 100%;
  }
`

const MapWrapper = styled.div`
  width: 100%;
  height: calc(100vh - var(--sizes-header-height));
  position: sticky;
  top: var(--sizes-header-height);
  display: none;

  @media ${minWidth('sm')} {
    display: block;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  /* padding bottom to prevent scroll-to on click to move map past bounds */

  @media ${minWidth('sm')} {
    padding-bottom: 500px;
  }

  @media ${minWidth('md')} {
    max-width: 373px;
  }

  @media ${minWidth('lg')} {
    max-width: 430px;
  }
`

type Props = CraftStoreLocatorStaticData

const StoreLocator = ({
  craftDataStoreLocator,
  craftDataProductTypes: productTypes,
  site,
}: Props) => {
  const [latitude, setLatitude] = useState<number | null>(null)
  const [longitude, setLongitude] = useState<number | null>(null)
  const [selectedStore, setSelectedStore] =
    useState<CraftStoreEntryFragment | null>(null)
  const foundStores = useStoresByDistance(latitude, longitude, site)

  const handleStoreClick = useCallback(
    (store: CraftStoreEntryFragment) => setSelectedStore(store ?? null),
    [],
  )

  const handleCoordinatesChange = useCallback(
    (lat: number | null, lng: number | null) => {
      setLatitude(lat)
      setLongitude(lng)
    },
    [],
  )

  const storeLocatorContent =
    craftDataStoreLocator.__typename === 'CraftStoreLocatorEntry'
      ? craftDataStoreLocator
      : undefined

  const stores = foundStores?.storesByDistance?.filter(stripMaybes)

  return (
    <>
      <Meta
        metaTitle={
          storeLocatorContent?.metaTitle ??
          getSeoPageTitle(t({ message: 'Store finder' }))
        }
        metaDescription={
          storeLocatorContent?.metaDescription ?? getSeoPageDescription()
        }
      />

      <MetaCanonical />

      {storeLocatorContent?.disclaimer && (
        <Bar>{storeLocatorContent.disclaimer}</Bar>
      )}

      <Container>
        <Wrapper>
          <SearchWrapper>
            <StoreLocatorTitle>{storeLocatorContent?.title}</StoreLocatorTitle>

            <StoreLocatorSearch
              stores={stores}
              productTypes={productTypes}
              selectedStore={selectedStore}
              onStoreClick={handleStoreClick}
              onCoordinatesChange={handleCoordinatesChange}
            />
          </SearchWrapper>

          <MapWrapper>
            <StoreLocatorMap
              stores={stores}
              onStoreClick={handleStoreClick}
              selectedStore={selectedStore}
            />
          </MapWrapper>
        </Wrapper>
      </Container>
    </>
  )
}

export default StoreLocator
