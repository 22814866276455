import { gql } from '@apollo/client'

import { CraftImageFragment } from './craftFragments'

export const CraftBlogPostCardFragment = gql`
  fragment CraftBlogPostCardFragment on CraftBlogPostEntry {
    uri
    title
    postDate

    image {
      ...CraftImageFragment
      desktopUrl: url(width: 480, height: 237)
      tabletUrl: url(width: 330, height: 237)
      mobileUrl: url(width: 330, height: 237)
    }

    cardIntroduction: richText1
    readMoreAlternative: text1
  }

  ${CraftImageFragment}
`
