import React, { SVGProps } from 'react'

const EyeSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 640 512"
    width="1em"
    height="1em"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M150.7 92.77C195 58.27 251.8 32 320 32c80.8 0 145.5 36.84 192.6 80.6 46.8 43.4 78.1 94.5 92.9 131.1 3.3 7.9 3.3 16.7 0 24.6-13.4 32.3-40.3 77.8-79.9 118.4l105.2 82.4c10.4 8.2 12.3 23.3 4.1 33.7-8.2 10.4-23.3 12.3-33.7 4.1L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196 13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zm39.1 30.73l46 36c22.5-19.6 52-31.5 84.2-31.5 70.7 0 128 57.3 128 128 0 21.2-5.1 41.1-14.2 58.7l53.8 42.2c33.5-34.1 58.3-73.8 71-100.9-14.5-30.9-40.2-72.5-78.7-108.3-41.1-38.1-94.7-68.6-159.9-68.6-50.5 0-94.9 18.63-130.2 44.4zm205.1 160.7c3.3-8.8 5.1-18.3 5.1-29.1 0-43.3-35.8-80-80-80-.7 0-1.3.9-2.9.9 2.2 5.1 2.9 10.5 2.9 15.1 0 11.1-2.4 20.7-6.6 29.2l81.5 63.9zm9.4 130.3l41.9 33C409.9 467.1 367.8 480 320 480c-80.8 0-145.5-36.8-192.6-80.6-46.78-44.3-78.06-95.4-92.94-131.1a31.98 31.98 0 010-24.6c9.54-22.9 25.83-52.5 48.63-82.2l37.71 29.7c-18.7 23.3-31.04 46.4-39.35 63.9 13.57 30.9 40.15 73.4 78.65 109.2C201.2 402.4 254.8 432 320 432c30.7 0 58.8-6.6 84.3-17.5zM192 255.1c0-2 .1-4.8.3-7.6l56.1 44.2c10.5 21.1 30.1 36.9 53.6 41.4l56.2 45.1c-12.1 2.9-24.9 5.8-39.1 5.8-69.8 0-128-57.3-128-128.9h.9z"
      fill="currentColor"
    />
  </svg>
)

export default EyeSlashIcon
