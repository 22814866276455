import React from 'react'
import { SearchAction, WebSite as WebSiteSchema } from 'schema-dts'

import { useStoreConfig } from '../lib/storeConfig'
import SchemaJsonLd from './SchemaJsonLd'

type QueryAction = SearchAction & {
  'query-input': string
}

interface Props extends Partial<WebSiteSchema> {
  queryUrlFragment?: string
}

const SchemaSearch = ({ queryUrlFragment, ...other }: Props) => {
  const { storeConfig } = useStoreConfig()

  if (!storeConfig?.baseUrl) {
    return null
  }

  const exploreAction: QueryAction = {
    '@type': 'SearchAction',
    target: `${storeConfig.baseUrl}${
      queryUrlFragment ?? 'search?query='
    }{query}`,
    'query-input': 'required name=query',
  }

  return (
    <SchemaJsonLd<WebSiteSchema>
      item={{
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: storeConfig.baseUrl,
        potentialAction: exploreAction,
        ...other,
      }}
    />
  )
}

export default SchemaSearch
