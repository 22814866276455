import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ComponentProps, useState } from 'react'

import { CrossIcon } from '@emico/icons'
import { minWidth, maxWidth } from '@emico/styles'
import Tooltip, { TooltipBubble } from '@emico/tooltip'
import { ButtonUnstyled, H3 } from '@emico/ui'

import EnvelopeIcon from '../icons/EnvelopeIcon'
import PhoneIcon from '../icons/PhoneIcon'
import { routes } from '../lib/routes'
import { CraftCustomerServiceOverviewStaticData } from '../pages/craft/customer-service/useCustomerServiceOverview'
import theme from '../theme'
import ButtonSecondary from './ButtonSecondary'
import CraftLink from './CraftLink'
import SectionHeader, { StyledSectionTitle } from './SectionHeader'

const StyledSectionHeader = styled(SectionHeader)`
  ${StyledSectionTitle} {
    @media ${minWidth('lg')} {
      font-size: ${theme.fontSizes['2xl']};
    }
  }
`

const Subtitle = styled.p`
  margin: ${theme.spacing.md} 0 0;
`

const OpeningHours = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.lg};

  &:last-child {
    margin-bottom: 0;
  }
`

const OpeningHoursTitle = styled(H3)`
  font-weight: ${theme.fontWeights.medium};
`

const ContactButtons = styled.div`
  display: grid;
  grid-gap: ${theme.spacing.md};

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing.lg};
  }
`

const buttonStyling = css`
  text-transform: none;
  font-weight: ${theme.fontWeights.normal};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  ${buttonStyling};
`

const PhoneNumberLinkMobile = styled(CraftLink)`
  ${buttonStyling};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const StyledButtonSecondaryMobile = styled(StyledButtonSecondary)`
  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledEnvelopeIcon = styled(EnvelopeIcon)`
  font-size: 13px;
  margin-right: ${theme.spacing.xs};
`

const StyledPhoneIcon = styled(PhoneIcon)`
  font-size: 16px;
  margin-right: ${theme.spacing.xs};
`

const StyledPhoneButton = styled(StyledButtonSecondary)`
  @media ${maxWidth('md')} {
    display: none;'
  }
`

const StyledTooltipBubble = styled(TooltipBubble)`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.lg};
`

const TooltipCloseButton = styled(ButtonUnstyled)`
  display: flex;
  padding: ${theme.spacing.sm};
  position: absolute;
  top: 0;
  right: 0;
`

const StyledCrossIcon = styled(CrossIcon)`
  font-size: 8px;
`

const PhoneNumberText = styled.span`
  color: ${theme.colors.primary};
  margin-top: ${theme.spacing.xxs};
`

type Props = Pick<
  CraftCustomerServiceOverviewStaticData,
  'craftDataCustomerServiceOverview'
>

const CustomerServiceContact = ({
  craftDataCustomerServiceOverview: overview,
  ...other
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  if (overview?.__typename !== 'CraftCustomerServiceOverviewEntry') {
    return null
  }

  const PhoneNumberLink = (props: ComponentProps<typeof CraftLink>) =>
    overview.phoneNumberLink ? (
      <PhoneNumberLinkMobile {...props} craftLink={overview.phoneNumberLink} />
    ) : (
      <></>
    )

  const TooltipButton = (props: ComponentProps<typeof Tooltip>) => (
    <Tooltip
      {...props}
      placement="top"
      open={showTooltip}
      bubble={
        <StyledTooltipBubble>
          <TooltipCloseButton
            analyticsContext="customerService.contact"
            analyticsName="call.tooltip.close"
            onClick={() => setShowTooltip(false)}
          >
            <StyledCrossIcon />
          </TooltipCloseButton>

          <Trans>
            Ask your question by phone.
            <PhoneNumberText>
              Reachable via {overview.phoneNumberLink?.customText}
            </PhoneNumberText>
          </Trans>
        </StyledTooltipBubble>
      }
    />
  )

  return (
    <div {...other}>
      {overview.contactTitle && (
        <StyledSectionHeader title={overview.contactTitle}>
          {overview.contactSubtitle && (
            <Subtitle>{overview.contactSubtitle}</Subtitle>
          )}
        </StyledSectionHeader>
      )}

      {(overview.openingHoursText1 || overview.openingHoursText2) && (
        <OpeningHours>
          <OpeningHoursTitle>
            <Trans>Opening hours:</Trans>
          </OpeningHoursTitle>

          {overview.openingHoursText1 && (
            <span>{overview.openingHoursText1}</span>
          )}

          {overview.openingHoursText2 && (
            <span>{overview.openingHoursText2}</span>
          )}
        </OpeningHours>
      )}

      <ContactButtons>
        <StyledButtonSecondary
          analyticsContext="customerService.contact"
          analyticsName="email"
          colorTheme="dark"
          withIcon
          href={routes.contact.base}
        >
          <StyledEnvelopeIcon />

          <Trans>Send an e-mail (recommended)</Trans>
        </StyledButtonSecondary>

        {overview.phoneNumberLink?.url && (
          <>
            <StyledButtonSecondaryMobile
              analyticsContext="customerService.contact"
              analyticsName="call.directly"
              colorTheme="dark"
              withIcon
              as={PhoneNumberLink}
            >
              <StyledPhoneIcon />

              <Trans>Ask your question by phone</Trans>
            </StyledButtonSecondaryMobile>

            <StyledPhoneButton
              analyticsContext="customerService.contact"
              analyticsName="call.tooltip.open"
              colorTheme="dark"
              withIcon
              as={TooltipButton}
              onClick={() => setShowTooltip(true)}
              isActive={showTooltip}
            >
              <StyledPhoneIcon />

              <Trans>Ask your question by phone</Trans>
            </StyledPhoneButton>
          </>
        )}
      </ContactButtons>
    </div>
  )
}

export default CustomerServiceContact
