import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import NextError from 'next/error'
import React from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { maxWidth, minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import CustomerServiceContact from '../../../components/CustomerServiceContact'
import CustomerServiceSubjectItems from '../../../components/CustomerServiceSubjectItems'
import CustomerServiceSubjectsMenu from '../../../components/CustomerServiceSubjectsMenu'
import CustomerServiceSubjectsMenuSimple from '../../../components/CustomerServiceSubjectsMenuSimple'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import PageHeader from '../../../components/PageHeader'
import { CraftCustomerServiceSubjectItemFragment } from '../../../lib/craftFragments.generated'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../lib/getSeoPageTitle'
import theme from '../../../theme'
import { CraftCustomerServiceOverviewStaticData } from './useCustomerServiceOverview'

const StyledContainer = styled(Container)`
  padding-bottom: ${theme.spacing['2xl']};
  padding-top: ${theme.spacing['2xl']};

  @media ${minWidth('lg')} {
    padding-bottom: ${theme.spacing['3xl']};
    padding-top: ${theme.spacing['3xl']};
  }
`

const StyledCustomerServiceSubjectsMenuSimpleMobile = styled(
  CustomerServiceSubjectsMenuSimple,
)`
  margin-bottom: ${theme.spacing['2xl']};

  @media ${minWidth('md')} {
    display: none;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: ${theme.spacing['2xl']};

  @media ${minWidth('lg')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing['3xl']};
  }
`

const StyledCustomerServiceSubjectsMenuTabletAndDesktop = styled(
  CustomerServiceSubjectsMenu,
)`
  margin-bottom: ${theme.spacing['2xl']};

  @media ${maxWidth('sm')} {
    display: none;
  }
`

type SubjectsType = Exclude<
  CraftCustomerServiceOverviewStaticData['craftDataCustomerServiceSubjects'],
  null
>

type SubjectItemsType =
  | Array<Maybe<CraftCustomerServiceSubjectItemFragment>>
  | undefined

/**
 *
 * @param subjects list of all customer service subjects
 * @returns an array of all subject items that should be shown in the quick FAQ overview
 */
const getQuickFaqItems = (subjects: SubjectsType) => {
  let items: SubjectItemsType = []

  subjects?.forEach(
    (subject) =>
      subject?.__typename === 'CraftFaqPageEntry' &&
      subject.faqItems?.forEach((item) => {
        if (item?.showOnFaqOverview) {
          items = [...(items ?? []), item]
        }
      }),
  )

  return items
}

type Props = CraftCustomerServiceOverviewStaticData

const CustomerServiceOverview = ({
  craftDataCustomerServiceOverview: overview,
  craftDataCustomerServiceSubjects: subjects,
}: Props) => {
  if (overview?.__typename !== 'CraftCustomerServiceOverviewEntry') {
    return <NextError statusCode={404} />
  }

  const headerImage = overview.image?.[0]
  const quickFaqItems = getQuickFaqItems(subjects)

  return (
    <>
      <Meta
        metaTitle={
          overview.metaTitle ??
          getSeoPageTitle(t({ message: 'Customer service' }))
        }
        metaDescription={overview.metaDescription ?? getSeoPageDescription()}
      />

      <MetaCanonical />

      <section>
        {overview.title && (
          <PageHeader
            title={overview.title}
            subtitle={overview.subtitle ?? undefined}
            image={headerImage}
          />
        )}

        {subjects?.length !== 0 && (
          <StyledContainer>
            <StyledCustomerServiceSubjectsMenuSimpleMobile
              craftDataCustomerServiceOverview={overview}
              craftDataCustomerServiceSubjects={subjects}
            />

            <StyledCustomerServiceSubjectsMenuTabletAndDesktop
              craftDataCustomerServiceOverview={overview}
              craftDataCustomerServiceSubjects={subjects}
            />

            <Grid>
              <CustomerServiceSubjectItems
                title={<Trans>Frequently asked questions</Trans>}
                subjectItems={quickFaqItems}
                visibleItemsCount={4}
              />

              <CustomerServiceContact
                craftDataCustomerServiceOverview={overview}
              />
            </Grid>
          </StyledContainer>
        )}
      </section>
    </>
  )
}

export default CustomerServiceOverview
