import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import NextError from 'next/error'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H2, H3 } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import { CraftCustomerServiceSubjectFragment } from '../lib/craftFragments.generated'
import { CraftCustomerServiceOverviewStaticData } from '../pages/craft/customer-service/useCustomerServiceOverview'
import theme from '../theme'
import CraftImage from './CraftImage'
import NextLink from './NextLink'

const Header = styled.header`
  margin-bottom: ${theme.spacing.lg};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const StyledNextLink = styled(NextLink)`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.md};
  padding-top: ${theme.spacing.md};
  border-top: ${theme.borders.default};
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover('currentColor', '1px', 'h3')};
  }
`

const StyledCraftImage = styled(CraftImage)`
  margin-right: ${theme.spacing.md};
  height: 25px;
  width: 25px;
  object-fit: contain;
`

interface Props extends CraftCustomerServiceOverviewStaticData {
  excludedSubject?: CraftCustomerServiceSubjectFragment
}

const CustomerServiceSubjectsMenuSimple = ({
  craftDataCustomerServiceOverview: overview,
  craftDataCustomerServiceSubjects: subjects,
  excludedSubject,
  ...other
}: Props) => {
  if (overview?.__typename !== 'CraftCustomerServiceOverviewEntry') {
    return <NextError statusCode={404} />
  }

  const filteredSubjects = excludedSubject
    ? subjects?.filter(
        (subject) =>
          subject?.__typename === 'CraftFaqPageEntry' &&
          subject.uid !== excludedSubject.uid,
      )
    : subjects

  const sortedSubjects = sortArrayByProperty(
    filteredSubjects ?? [],
    (subject) => subject?.__typename === 'CraftFaqPageEntry' && subject.order,
  )

  return (
    <div {...other}>
      <Header>
        <H2>
          <Trans>Help subjects</Trans>
        </H2>
      </Header>

      <nav>
        <List>
          {sortedSubjects?.map((subject) => {
            if (subject?.__typename !== 'CraftFaqPageEntry') {
              return null
            }
            const subjectLogo = subject.logo?.[0]

            return (
              <li key={subject.uid}>
                <StyledNextLink
                  analyticsContext="customerService.overview"
                  analyticsName={`subject.${subject.title}`}
                  href={`/${overview.slug}/${subject.slug}`}
                >
                  {subjectLogo && (
                    <StyledCraftImage
                      image={subjectLogo}
                      width={25}
                      height={25}
                    />
                  )}

                  <H3>{subject.title}</H3>
                </StyledNextLink>
              </li>
            )
          })}
        </List>
      </nav>
    </div>
  )
}

export default CustomerServiceSubjectsMenuSimple
