/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GoogleMapsGeocode,
  useGoogleMapsGeocode,
} from '@emico-hooks/google-maps-geocode'
import styled from '@emotion/styled'
import { t, Plural, Trans } from '@lingui/macro'
import { getDistance } from 'geolib'
import React, { useState, useCallback, useMemo } from 'react'
import { Collapse } from 'react-collapse'
import { useForm } from 'react-hook-form'

import { SearchIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled, H2 } from '@emico/ui'

import FilterIcon from '../icons/FilterIcon'
import {
  CraftStoreEntryFragment,
  ProductTypesQuery,
} from '../pages/craft/store-locator/useStoreLocator.generated'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import CheckboxCustom from './CheckboxCustom'
import Input from './Input'
import StoreLocatorResultsListCard from './StoreLocatorResultsListCard'

const FormRow = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.xs};
  width: 100%;
`

const StyledStoreLocatorResultsListCard = styled(StoreLocatorResultsListCard)`
  margin-top: ${theme.spacing.lg};

  &:last-of-type {
    margin-bottom: ${theme.spacing.lg};
  }
`

const Filters = styled.div`
  background-color: ${theme.colors.background};
  position: sticky;
  top: var(--sizes-header-height);
  padding-top: ${theme.spacing.md};
  z-index: 1;
`

const StyledInput = styled(Input as any)`
  flex-grow: 1;
` as typeof Input

const Form = styled.form`
  display: flex;
  width: 100%;
  margin: ${theme.spacing.md} 0;
  flex-direction: column;
  gap: ${theme.spacing.md};
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  height: 50px;
  flex-shrink: 0;
  background: ${theme.colors.grayDark};
  border-color: ${theme.colors.grayDark};
  max-width: 50px;
  width: 100%;
  padding: 0;
  border-radius: 0;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 20px;
`

const ErrorText = styled.p`
  color: ${theme.colors.primary};
`

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: 1px solid ${theme.colors.grayMiddle};
`

const SearchResultText = styled.p`
  margin-bottom: 0;
  font-size: ${theme.fontSizes.sm};
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSizes.md};
  border: ${theme.borders.default};
  padding: ${theme.spacing.xs} ${theme.spacing.md};
`

const StyledFilterIcon = styled(FilterIcon)`
  font-size: 15px;
  margin-left: ${theme.spacing.sm};
`

const FilterItems = styled.ul`
  margin: ${theme.spacing.md};
  padding: 0;
  max-width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 ${theme.spacing.xl};
`

const FilterItem = styled.li`
  display: flex;
  margin-bottom: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    flex-basis: 50%;
  }
`

const FiltersTitle = styled(H2)`
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

interface FormValues {
  search: string
  productType: string[]
}

interface Props {
  stores?: CraftStoreEntryFragment[] | null
  productTypes: Exclude<ProductTypesQuery['entries'], null>
  selectedStore: CraftStoreEntryFragment | null
  onStoreClick?: (location: CraftStoreEntryFragment) => void
  onCoordinatesChange: (lat: number | null, lng: number | null) => void
}

const StoreLocatorSearch = ({
  stores,
  productTypes,
  selectedStore,
  onStoreClick,
  onCoordinatesChange,
}: Props) => {
  const googleMapsGeocode = useGoogleMapsGeocode()
  const { register, handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {
      productType: [],
      search: '',
    },
  })
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [geoCodeResults, setGeoCodeResults] = useState<GoogleMapsGeocode>(null)
  const [hasError, setHasError] = useState(false)

  const productTypeFilter = watch('productType')

  const productTypeFilters = productTypes?.filter(
    (productType) =>
      productType?.__typename === 'CraftProductTypeEntry' &&
      productType.isFilter,
  )

  const toggleFilters = useCallback(() => {
    setShowFilters((showFilters) => !showFilters)
  }, [])

  const handleLocationsByGeoSearch = useCallback(
    (results: GoogleMapsGeocode) => {
      const location = results?.[0]?.geometry?.location

      if (location) {
        onCoordinatesChange(location.lat ?? null, location.lng ?? null)
      }
    },
    [onCoordinatesChange],
  )

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const results = await googleMapsGeocode(values.search ?? null)

        setGeoCodeResults(results)
        handleLocationsByGeoSearch(results)
      } catch {
        setHasError(true)
      }
    },
    [googleMapsGeocode, handleLocationsByGeoSearch],
  )

  const getLocationDistance = (store: CraftStoreEntryFragment) => {
    const geoLocation = geoCodeResults?.[0]?.geometry?.location

    return Math.ceil(
      getDistance(
        {
          latitude: geoLocation?.lat ?? 0,
          longitude: geoLocation?.lng ?? 0,
        },
        {
          latitude: store.latitude ?? 0,
          longitude: store.longitude ?? 0,
        },
      ) / 1000,
    )
  }

  const locationErrorMessage = {
    required: t({
      message: 'Enter your location here',
    }),
  }

  const filteredLocations = useMemo(() => {
    const locations: typeof stores = []

    if (!stores) {
      return locations
    }

    if (!productTypeFilter || productTypeFilter.length === 0) {
      return stores
    }

    return stores.filter((location) =>
      location.productTypes?.some((item) =>
        productTypeFilter.includes(item?.uid ?? ''),
      ),
    )
  }, [stores, productTypeFilter])

  return (
    <>
      <Filters>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <StyledInput
              control={control}
              placeholder={t({
                message: 'Location',
              })}
              label={t({
                message: 'Location',
              })}
              {...register('search', {
                // Show input error when input has no value on submit
                required: {
                  value: true,
                  message: locationErrorMessage.required,
                },
                onChange: () => {
                  if (hasError) {
                    setHasError(false)
                  }
                },
              })}
            />

            <StyledButtonPrimary
              type="submit"
              analyticsName="Storelocator search button"
              analyticsContext="storelocator"
              ariaLabel={t({ message: 'Search' })}
            >
              <StyledSearchIcon />
            </StyledButtonPrimary>
          </FormRow>

          {stores && (
            <div>
              <SearchHeader>
                <SearchResultText>
                  <Plural
                    value={stores.length}
                    one="# store in your neighborhood."
                    other="# stores in your neighborhood."
                  />
                </SearchResultText>

                <StyledButtonUnstyled
                  analyticsContext="storelocator.filters"
                  analyticsName="filters.show"
                  onClick={toggleFilters}
                >
                  <Trans>Filter</Trans>
                  <StyledFilterIcon />
                </StyledButtonUnstyled>
              </SearchHeader>

              <div>
                <Collapse isOpened={showFilters}>
                  <FiltersTitle>
                    <Trans>Filters</Trans>
                  </FiltersTitle>

                  {productTypeFilters?.length !== 0 ? (
                    <FilterItems>
                      {productTypeFilters?.map((productType) =>
                        productType?.__typename === 'CraftProductTypeEntry' ? (
                          <FilterItem key={productType.title}>
                            <CheckboxCustom
                              value={productType.uid ?? ''}
                              {...register('productType')}
                            >
                              {productType.title}
                            </CheckboxCustom>
                          </FilterItem>
                        ) : null,
                      )}
                    </FilterItems>
                  ) : null}
                </Collapse>
              </div>
            </div>
          )}
        </Form>
      </Filters>

      {hasError && (!stores || stores?.length === 0) ? (
        <ErrorText>
          <Trans>Could not fetch stores, please try again later</Trans>
        </ErrorText>
      ) : null}

      {geoCodeResults && geoCodeResults.length === 0 ? (
        <ErrorText>
          <Trans>We couldn't find that location.</Trans>
        </ErrorText>
      ) : null}

      {filteredLocations?.map((location) => (
        <StyledStoreLocatorResultsListCard
          key={location.uid}
          location={location}
          showDistanceLabel
          calculateDistance={getLocationDistance}
          isSelected={location.uid === selectedStore?.uid}
          onStoreClick={onStoreClick}
        />
      ))}
    </>
  )
}

export default StoreLocatorSearch
