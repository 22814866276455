import React, { SVGProps } from 'react'

const RoundCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64.28a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm3.467 6.647a.728.728 0 1 0-1.117-.93L7.863 9.747 6.245 8.13a.727.727 0 0 0-1.029 1.029l2.182 2.182a.727.727 0 0 0 1.073-.05l3.636-4.363Z"
      fill="currentColor"
    />
  </svg>
)

export default RoundCheckIcon
