export enum CraftTypeHandleEnum {
  HOMEPAGE = 'homepage',
  PAGE = 'default',
  BLOG = 'blogOverview',
  BLOG_POST = 'blogPost',
  CUSTOMER_SERVICE_OVERVIEW = 'customerServiceOverview',
  CUSTOMER_SERVICE_SUBJECT = 'faqPage',
  STORE_LOCATOR = 'storeLocator',
  STORE = 'store',
  INFORMATION_OVERVIEW = 'informationOverview',
  INFORMATION_OVERVIEW_GO_CARTS = 'informationSubjectGoCarts',
  INFORMATION_GO_CARTS = 'informationGoCarts',
  INFORMATION_OVERVIEW_TRAMPOLINES = 'informationSubjectTrampolines',
  INFORMATION_TRAMPOLINES = 'informationTrampolines',
  INFORMATION_OVERVIEW_RIDE_ON_CARS = 'informationSubjectRideOnCars',
  INFORMATION_RIDE_ON_CARS = 'informationRideOnCars',
  INFORMATION_OVERVIEW_BALANCE_BIKES = 'informationSubjectBalanceBikes',
  INFORMATION_BALANCE_BIKES = 'informationBalanceBikes',
  INFORMATION_OVERVIEW_SCOOTERS = 'informationSubjectScooters',
  INFORMATION_SCOOTERS = 'informationScooters',
  INFORMATION_OVERVIEW_PLAYGROUND_EQUIPMENT = 'informationSubjectPlaygroundEquipment',
  INFORMATION_PLAYGROUND_EQUIPMENT = 'informationPlaygroundEquipment',
  REQUEST_LICENSE_PLATE = 'requestLicensePlate',
}
