/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { PlateInputFormValues } from '../lib/useCreatePlate'
import Input from './Input'

const plateTextPattern = /^[A-Z0-9€ØÄÜÖÅÆË+&?.!,:\-\s]+$/

const StyledInput = styled(Input as any)`
  @media ${minWidth('md')} {
    width: 100%;
  }
` as typeof Input

interface Props {
  control: Control<PlateInputFormValues>
}

const FormFieldPlateText = ({ control }: Props) => {
  const [value, setValue] = useState('')

  const errorMessages = {
    required: t({
      message: 'This field is required',
    }),
    pattern: t({
      message:
        'Only letters, numbers, spaces and the symbols € Ø Ä Ü Ö Å Æ Ë + & ? ! . , : - are allowed',
    }),
  }

  // control is passed twice unfortunately: one for RHF Controller & one for the Input component.
  return (
    <Controller
      name="plateText"
      control={control}
      rules={{
        required: {
          value: true,
          message: errorMessages.required,
        },
        pattern: {
          value: plateTextPattern,
          message: errorMessages.pattern,
        },
      }}
      render={({ field }) => (
        <StyledInput
          {...field}
          control={control}
          maxLength={10}
          value={value}
          onChange={(event) => {
            const plateTextValue = event.target.value.toLocaleUpperCase()

            // send to RHF
            field.onChange(plateTextValue)
            // UI state
            setValue(plateTextValue)
          }}
          label={t({
            message: `Fill in your inscription`,
          })}
        />
      )}
    />
  )
}

export default FormFieldPlateText
