import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Router, useRouter } from 'next/router'
import React, { ReactNode, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { Maybe } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import { CraftCustomerServiceSubjectItemFragment } from '../lib/craftFragments.generated'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import HtmlContent from './HtmlContent'
import SectionTitle from './SectionTitle'

const StyledSectionTitle = styled(SectionTitle)`
  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

const SubjectItems = styled.div`
  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const Header = styled.header`
  padding-bottom: ${theme.spacing.lg};
  border-bottom: ${theme.borders.default};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.md} 0;
  }
`

const ItemContent = styled.div`
  padding-top: ${theme.spacing.md};
  padding-bottom: ${theme.spacing.lg};
`

const LoadMoreItems = styled('div', {
  shouldForwardProp: (prop) => !['show'].includes(prop.toString()),
})<{ show: boolean }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ show }) => (show ? 1 : 0)};
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  margin-top: ${theme.spacing.md};
  width: 100%;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ state }) => opacityStates[state]};
  font-weight: ${theme.fontWeights.medium};
  text-align: left;
`

type SubjectItemsType =
  | Array<Maybe<CraftCustomerServiceSubjectItemFragment>>
  | undefined

type RouterQuery = Exclude<Router['query'], null>

const renderFaqItems = (items: SubjectItemsType, routerQuery?: RouterQuery) =>
  items?.map((item) => {
    if (!item?.text) {
      return null
    }

    return (
      <StyledAccordion
        key={item?.faqTitle}
        label={<span>{item?.faqTitle}</span>}
        isCollapsedDefault={item.urlKey === routerQuery?.subject}
      >
        <ItemContent>
          <HtmlContent html={item?.text} />
        </ItemContent>
      </StyledAccordion>
    )
  })

interface Props {
  title: ReactNode
  subjectItems: Array<Maybe<CraftCustomerServiceSubjectItemFragment>>
  visibleItemsCount?: number
}

const CustomerServiceSubjectItems = ({
  title,
  subjectItems,
  visibleItemsCount = 10,
  ...other
}: Props) => {
  const router = useRouter()
  const [showAll, setShowAll] = useState<boolean>(false)
  const nodeRef = useRef(null)
  const initialItems = subjectItems?.slice(0, visibleItemsCount)
  const loadMoreItems = subjectItems?.slice(visibleItemsCount)
  const showLoadMore = subjectItems?.length > visibleItemsCount

  return (
    <SubjectItems {...other}>
      <Header>
        <StyledSectionTitle title={title} />
      </Header>

      {renderFaqItems(initialItems, router.query)}

      {showLoadMore && (
        <>
          <Collapse isOpened={showAll}>
            <LoadMoreItems show={showAll}>
              {renderFaqItems(loadMoreItems, router.query)}
            </LoadMoreItems>
          </Collapse>

          <Transition nodeRef={nodeRef} in={!showAll} timeout={800}>
            {(state: TransitionStatus) => (
              <StyledButtonUnstyled
                state={state}
                analyticsContext="CustomerService.subjectItems"
                analyticsName="readMore"
                onClick={() => setShowAll(true)}
              >
                <Trans>Load more</Trans>...
              </StyledButtonUnstyled>
            )}
          </Transition>
        </>
      )}
    </SubjectItems>
  )
}

export default CustomerServiceSubjectItems
