import { getQueryUnion } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import NextError from 'next/error'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import ButtonSecondary from '../../../../components/ButtonSecondary'
import Container from '../../../../components/Container'
import CraftBlock from '../../../../components/CraftBlock'
import { HighlightBanner } from '../../../../components/CraftHighlightBannerLinkBlock'
import HtmlContent from '../../../../components/HtmlContent'
import Meta from '../../../../components/Meta'
import MetaCanonical from '../../../../components/MetaCanonical'
import NextLinkPrimary from '../../../../components/NextLinkPrimary'
import PageHeader from '../../../../components/PageHeader'
import CheckmarkIcon from '../../../../icons/CheckmarkIcon'
import LocationMarkerIcon from '../../../../icons/LocationMarkerIcon'
import getSeoPageDescription from '../../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../../lib/getSeoPageTitle'
import theme from '../../../../theme'
import { CraftStoreLocatorStoreStaticData } from './useStore'

type Props = CraftStoreLocatorStoreStaticData

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing.md};
  }
`

const StyledContainer = styled(Container)`
  padding-bottom: ${theme.spacing['2xl']};
  padding-top: ${theme.spacing['2xl']};
  max-width: 924px;

  @media ${minWidth('lg')} {
    padding-bottom: ${theme.spacing['3xl']};
  }
`

const Left = styled.div`
  grid-column: span 1;
`

const Description = styled.div`
  padding-top: ${theme.spacing.md};
  padding-bottom: ${theme.spacing['3xl']};
`

const Right = styled.div`
  grid-column: span 2;
`

const StyledH2 = styled(H2)`
  margin-bottom: ${theme.spacing.md};
`

const AssortmentList = styled.ul`
  column-count: 2;
  column-gap: ${theme.spacing.xl};
  padding: 0;

  @media ${minWidth('md')} {
    padding-left: ${theme.spacing.xl};
  }

  @media ${minWidth('lg')} {
    column-count: 3;
  }
`

const AssortmentItem = styled.li`
  list-style: none;
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
`

const StyledLocationMarkerIcon = styled(LocationMarkerIcon)`
  margin-right: ${theme.spacing.xs};
  font-size: 21px;
`

const Check = styled(CheckmarkIcon)`
  min-width: 16px;
`

const StoreLocatorStore = ({
  craftDataStoreLocatorStore: craftDataStore,
  storeLocatorData,
  storeLocatorBannerData,
}: Props) => {
  if (!craftDataStore?.id) {
    return <NextError statusCode={404} />
  }

  const banner = storeLocatorBannerData?.genericBlockLink?.[0]
    ? getQueryUnion(
        storeLocatorBannerData?.genericBlockLink[0],
        'CraftGenericBlockLinkEntry',
      )
    : undefined

  const breadcrumbName = {
    name: craftDataStore.title ?? undefined,
  }

  return (
    <>
      <Meta
        metaTitle={
          craftDataStore?.metaTitle ??
          getSeoPageTitle(
            t({ message: `Store finder | ${craftDataStore.title}` }),
          )
        }
        metaDescription={
          craftDataStore?.metaDescription ?? getSeoPageDescription()
        }
      />

      <MetaCanonical />

      <PageHeader
        image={
          craftDataStore.image?.[0] ?? storeLocatorData?.image?.[0] ?? null
        }
        title={craftDataStore.title ?? ''}
        subtitle={
          <>
            <StyledLocationMarkerIcon />

            {[craftDataStore.city, craftDataStore.country]
              .filter(Boolean)
              .join(', ')}
          </>
        }
      />

      <Container>
        <Breadcrumbs customName={breadcrumbName} />
      </Container>

      <StyledContainer>
        <H2>{craftDataStore.title}</H2>

        <Description>
          <HtmlContent html={craftDataStore.description ?? ''} />
        </Description>

        <Grid>
          <Left>
            <StyledH2>
              <Trans>Address</Trans>
            </StyledH2>

            <p>
              {craftDataStore.address1}
              {craftDataStore.address2 ? (
                <>
                  <br />
                  {craftDataStore.address2}
                </>
              ) : null}
              <br />
              {craftDataStore.zipcode} {craftDataStore.city}
            </p>

            <p>
              {craftDataStore.phoneNumber && (
                <>
                  <Trans>Phone number: {craftDataStore.phoneNumber}</Trans>
                  <br />
                </>
              )}

              {craftDataStore.email && (
                <>
                  <Trans>
                    Email address:{' '}
                    <NextLinkPrimary
                      analyticsContext="store.locator.store"
                      analyticsName="email"
                      href={`mailto:${craftDataStore.email}`}
                      isBasic
                    >
                      {craftDataStore.email}
                    </NextLinkPrimary>
                  </Trans>

                  <br />
                </>
              )}

              {craftDataStore.website && (
                <Trans>
                  Website:{' '}
                  <NextLinkPrimary
                    analyticsContext="store.locator.store"
                    analyticsName="website"
                    href={craftDataStore.website}
                    isBasic
                    target="_blank"
                  >
                    {craftDataStore.website}
                  </NextLinkPrimary>
                </Trans>
              )}
            </p>

            <ButtonSecondary
              colorTheme="dark"
              target="_blank"
              rel="external nofollow"
              href={`https://maps.google.com/?q=${craftDataStore.latitude},${craftDataStore.longitude}`}
            >
              <Trans>Plan your route</Trans>
            </ButtonSecondary>
          </Left>

          <Right>
            <StyledH2>
              <Trans>Assortment</Trans>
            </StyledH2>

            <p>{storeLocatorData?.disclaimerAssortment}</p>

            <AssortmentList>
              {craftDataStore.productTypes.map((item) => (
                <AssortmentItem key={item?.title}>
                  <Check /> {item?.title}
                </AssortmentItem>
              ))}
            </AssortmentList>
          </Right>
        </Grid>
      </StyledContainer>

      {banner && (
        <CraftBlock>
          <Container>
            <HighlightBanner
              item={banner}
              isFullWidth
              variant="backgroundImage"
              showIcon
            />
          </Container>
        </CraftBlock>
      )}
    </>
  )
}

export default StoreLocatorStore
