import React, { SVGProps } from 'react'

const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M12.756 0H1.248C.994 0 .75.132.57.366.39.601.29.918.29 1.25v10c0 .332.1.65.28.884.18.234.424.366.678.366h11.508c.254 0 .498-.132.678-.366.18-.235.28-.553.28-.884v-10c0-.332-.1-.65-.28-.884C13.254.132 13.01 0 12.756 0zM11.7 1.25L7.002 5.487 2.303 1.25h9.398zm-10.453 10V1.819l5.48 4.944c.081.072.177.111.274.111a.407.407 0 00.274-.111l5.48-4.944v9.431H1.248z"
      fill="currentColor"
    />
  </svg>
)

export default EnvelopeIcon
