import React, { SVGProps } from 'react'

const LocationMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 23"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M9.51.563A8.604 8.604 0 00.916 9.155c0 7.354 7.813 12.91 8.145 13.145a.81.81 0 00.898 0c.332-.235 8.145-5.791 8.145-13.145A8.603 8.603 0 009.51.562zm0 5.468a3.125 3.125 0 110 6.25 3.125 3.125 0 010-6.25z"
      fill="currentColor"
    />
  </svg>
)

export default LocationMarkerIcon
