/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { useCodeStatus } from '../lib/useCodeStatus'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import Input from './Input'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  margin: ${theme.spacing.lg} 0 ${theme.spacing.xl} 0;

  @media ${minWidth('md')} {
    flex-direction: row;
    gap: ${theme.spacing.sm};
    max-width: 560px;
  }
`

const StyledInput = styled(Input as any)`
  @media ${minWidth('md')} {
    width: 100%;
  }
` as typeof Input

const StyledButtonPrimary = styled(ButtonPrimary)`
  max-height: 50px;
`

export type SubmitCodeFormValues = {
  code: string
}

interface Props {
  submitCodeForm: UseFormReturn<SubmitCodeFormValues>
  onIsValid: () => void
}

const SubmitCodeForm = ({ submitCodeForm, onIsValid }: Props) => {
  const { submitCode, hasError, isValid, isInvalid, isUsed } = useCodeStatus()
  const [showError, setShowError] = useState(false)

  const { register, control, handleSubmit } = submitCodeForm
  const codeValue = submitCodeForm.watch('code')

  const codeErrorMessages = {
    required: t({
      message: 'Enter your unique code',
    }),
    error: t({
      message: 'Could not fetch code status',
    }),
    invalid: t({
      message: 'The code is not valid',
    }),
    used: t({
      message: 'The code has already been used',
    }),
  }

  const getCustomErrorMessage = () => {
    if (!showError) {
      return
    }

    if (hasError) {
      return codeErrorMessages.error
    }

    if (isInvalid) {
      return codeErrorMessages.invalid
    }

    if (isUsed) {
      return codeErrorMessages.used
    }

    return
  }

  const onSubmit = ({ code }: SubmitCodeFormValues) => {
    submitCode(code)
  }

  useEffect(() => {
    if (hasError || isInvalid || isUsed) {
      setShowError(true)
    }
  }, [hasError, isUsed, isInvalid])

  useEffect(() => {
    if (isValid) {
      onIsValid()
    }
  }, [isValid, onIsValid])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledInput
        control={control}
        label={t({
          message: `Fill in your unique code`,
        })}
        customErrorMessage={getCustomErrorMessage()}
        {...register('code', {
          required: {
            value: true,
            message: codeErrorMessages.required,
          },
          onChange: () => {
            if (showError) {
              setShowError(false)
            }
          },
        })}
      />

      <StyledButtonPrimary
        analyticsContext="request.license.plate"
        analyticsName="redeem"
        colorType="neutral"
        disabled={!codeValue}
      >
        <Trans>Redeem</Trans>
      </StyledButtonPrimary>
    </Form>
  )
}

export default SubmitCodeForm
