import { GetStaticProps } from 'next'

import { addApolloState, initializeApollo } from '../apollo/apolloClient'
import DefaultLayout from '../components/DefaultLayout'
import { getStaticProps as getDefaultLayoutStaticProps } from '../lib/useCraftGlobalSets'
import { getStaticProps as getCraftNotFoundStaticProps } from '../lib/useCraftNotFoundPage'
import { RouteProps } from './[[...slug]].page'
import CraftEntrySwitch from './craft'

const NotFoundPage = ({ resolvedRoute, pageProps }: RouteProps) => (
  <DefaultLayout websiteData={pageProps?.websiteData}>
    <CraftEntrySwitch pageProps={pageProps} resolvedRoute={resolvedRoute} />
  </DefaultLayout>
)

export const getStaticProps: GetStaticProps = async ({
  locale,
  defaultLocale,
}) => {
  const client = initializeApollo(locale ?? defaultLocale ?? '')
  const { websiteData } = await getDefaultLayoutStaticProps(client, locale)
  const { craftDataPage } = await getCraftNotFoundStaticProps(client, locale)

  return addApolloState(client, {
    props: {
      pageProps: {
        websiteData,
        craftDataPage,
      },
    },
  })
}

export default NotFoundPage
