import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import { CraftInformationStaticData } from '../pages/craft/information/useInformation'
import theme from '../theme'
import NextLink from './NextLink'
import SectionHeader from './SectionHeader'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const GridList = styled(List)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const InformationSubject = styled.div`
  padding: ${theme.spacing.lg};
  height: 100%;
  border: ${theme.borders.default};
`

const SubjectHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.lg};
  padding-bottom: ${theme.spacing.lg};
  border-bottom: ${theme.borders.default};
`

const SubjectTitle = styled(H3)`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const InformationSubjectItemList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const InformationSubjectItem = styled.li`
  margin-bottom: ${theme.spacing.md};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledNextLink = styled(NextLink)`
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const ShowAllLink = styled(StyledNextLink)`
  font-weight: ${theme.fontWeights.medium};
`

type Props = Pick<
  CraftInformationStaticData,
  'craftDataInformationSubjects' | 'craftDataInformationItems'
>

const InformationSubjectMenu = ({
  craftDataInformationSubjects: subjects,
  craftDataInformationItems: items,
  ...other
}: Props) => {
  const sortedSubjects = sortArrayByProperty(
    subjects ?? [],
    (subject) => subject && 'order' in subject && subject.order,
  )

  return (
    <div {...other}>
      <SectionHeader
        title={<Trans>Information subjects</Trans>}
        hasLargeTitle
      />

      <nav>
        <GridList>
          {sortedSubjects?.map((subject) => {
            if (
              subject?.__typename !== 'CraftInformationSubjectGoCartsEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectTrampolinesEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectRideOnCarsEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectBalanceBikesEntry' &&
              subject?.__typename !== 'CraftInformationSubjectScootersEntry' &&
              subject?.__typename !==
                'CraftInformationSubjectPlaygroundEquipmentEntry'
            ) {
              return null
            }

            const subjectType = subject.typeHandle?.replace(
              'informationSubject',
              '',
            )

            // Filter items from all information subjects to only show items from the current information subject
            const informationSubjectItems = items?.filter((item) => {
              if (
                item?.__typename !== 'CraftInformationGoCartsEntry' &&
                item?.__typename !== 'CraftInformationTrampolinesEntry' &&
                item?.__typename !== 'CraftInformationRideOnCarsEntry' &&
                item?.__typename !== 'CraftInformationBalanceBikesEntry' &&
                item?.__typename !== 'CraftInformationScootersEntry' &&
                item?.__typename !== 'CraftInformationPlaygroundEquipmentEntry'
              ) {
                return null
              }

              const itemType = item?.typeHandle?.replace('information', '')

              return subjectType === itemType
            })

            const totalInformationSubjectItemsCount =
              informationSubjectItems?.length ?? 0

            const visibleInformationSubjectItems =
              informationSubjectItems?.slice(0, 3)

            if (totalInformationSubjectItemsCount === 0) {
              return null
            }

            return (
              <li key={subject.uid}>
                <InformationSubject>
                  <SubjectHeader>
                    <SubjectTitle>{subject.title}</SubjectTitle>
                  </SubjectHeader>

                  <InformationSubjectItemList>
                    {visibleInformationSubjectItems?.map((item) => {
                      if (
                        item?.__typename !== 'CraftInformationGoCartsEntry' &&
                        item?.__typename !==
                          'CraftInformationTrampolinesEntry' &&
                        item?.__typename !==
                          'CraftInformationRideOnCarsEntry' &&
                        item?.__typename !==
                          'CraftInformationBalanceBikesEntry' &&
                        item?.__typename !== 'CraftInformationScootersEntry' &&
                        item?.__typename !==
                          'CraftInformationPlaygroundEquipmentEntry'
                      ) {
                        return null
                      }

                      const itemShortTitle =
                        item.informationItemShort?.[0]?.itemTitle

                      const itemSubject =
                        item?.informationItemShort?.[0]?.informationSubject?.[0]
                          ?.__typename === 'CraftInformationItemSubjectEntry'
                          ? item.informationItemShort[0].informationSubject[0]
                              .title
                          : ''

                      return (
                        <InformationSubjectItem key={item.uid}>
                          <StyledNextLink
                            analyticsContext="information.subject"
                            analyticsName={`item.${item.title}`}
                            href={{
                              pathname: `/${subject.uri}`,
                              query: {
                                itemSlug: item.slug,
                                itemSubject: itemSubject,
                              },
                            }}
                          >
                            {itemShortTitle}
                          </StyledNextLink>
                        </InformationSubjectItem>
                      )
                    })}

                    {totalInformationSubjectItemsCount > 3 && (
                      <InformationSubjectItem>
                        <ShowAllLink
                          analyticsContext="information.subject"
                          analyticsName={`showAll.${subject.title}`}
                          href={`/${subject.uri}`}
                        >
                          <Trans>Show all</Trans>
                        </ShowAllLink>
                      </InformationSubjectItem>
                    )}
                  </InformationSubjectItemList>
                </InformationSubject>
              </li>
            )
          })}
        </GridList>
      </nav>
    </div>
  )
}

export default InformationSubjectMenu
