import NextError from 'next/error'
import React from 'react'

import { CraftTypeHandleEnum } from '../../lib/CraftTypeHandleEnum'
import { RouteProps } from '../[[...slug]].page'
import Blog from './blog'
import BlogPost from './blog/post'
import CustomerServiceOverview from './customer-service'
import CustomerServiceSubject from './customer-service/subject'
import Information from './information'
import InformationDetail from './information/detail'
import InformationSubject from './information/subject'
import CraftPage from './page'
import RequestLicensePlate from './request-license-plate'
import StoreLocator from './store-locator'
import StoreLocatorStore from './store-locator/store'

/**
 * CraftEntrySwitch component renders craft data components, based on resolvedRoute entryType
 */

type Props = Pick<RouteProps, 'pageProps' | 'resolvedRoute'>

const CraftEntrySwitch = ({ pageProps, resolvedRoute }: Props) => {
  switch (resolvedRoute?.entryType) {
    case CraftTypeHandleEnum.HOMEPAGE:
    case CraftTypeHandleEnum.PAGE:
      if (!pageProps || !('craftDataPage' in pageProps)) {
        break
      }

      return <CraftPage {...pageProps} />
    case CraftTypeHandleEnum.BLOG:
      if (!pageProps || !('craftDataBlog' in pageProps)) {
        break
      }

      return <Blog {...pageProps} />
    case CraftTypeHandleEnum.BLOG_POST:
      if (!pageProps || !('craftDataBlogPost' in pageProps)) {
        break
      }

      return <BlogPost {...pageProps} />
    case CraftTypeHandleEnum.CUSTOMER_SERVICE_OVERVIEW:
      if (!pageProps || !('craftDataCustomerServiceOverview' in pageProps)) {
        break
      }

      return <CustomerServiceOverview {...pageProps} />
    case CraftTypeHandleEnum.CUSTOMER_SERVICE_SUBJECT:
      if (!pageProps || !('craftDataCustomerServiceSubject' in pageProps)) {
        break
      }

      return <CustomerServiceSubject {...pageProps} />
    case CraftTypeHandleEnum.STORE_LOCATOR:
      if (!pageProps || !('craftDataStoreLocator' in pageProps)) {
        break
      }

      return <StoreLocator {...pageProps} />
    case CraftTypeHandleEnum.STORE:
      if (!pageProps || !('craftDataStoreLocatorStore' in pageProps)) {
        break
      }

      return <StoreLocatorStore {...pageProps} />
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW:
      if (!pageProps || !('craftDataInformation' in pageProps)) {
        break
      }

      return <Information {...pageProps} />
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_GO_CARTS:
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_TRAMPOLINES:
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_RIDE_ON_CARS:
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_BALANCE_BIKES:
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_SCOOTERS:
    case CraftTypeHandleEnum.INFORMATION_OVERVIEW_PLAYGROUND_EQUIPMENT:
      if (
        !pageProps ||
        !('craftDataInformationSubject' in pageProps) ||
        !('craftDataInformationSubjectItems' in pageProps) ||
        !('craftDataInformationItemSubjects' in pageProps)
      ) {
        break
      }

      return <InformationSubject {...pageProps} />
    case CraftTypeHandleEnum.INFORMATION_GO_CARTS:
    case CraftTypeHandleEnum.INFORMATION_TRAMPOLINES:
    case CraftTypeHandleEnum.INFORMATION_RIDE_ON_CARS:
    case CraftTypeHandleEnum.INFORMATION_BALANCE_BIKES:
    case CraftTypeHandleEnum.INFORMATION_SCOOTERS:
    case CraftTypeHandleEnum.INFORMATION_PLAYGROUND_EQUIPMENT:
      if (
        !pageProps ||
        !('craftDataInformationSubject' in pageProps) ||
        !('craftDataInformationDetail' in pageProps) ||
        !('craftDataInformationSubjectItems' in pageProps)
      ) {
        break
      }

      return <InformationDetail {...pageProps} />
    case CraftTypeHandleEnum.REQUEST_LICENSE_PLATE:
      if (!pageProps || !('craftDataRequestLicensePlate' in pageProps)) {
        break
      }

      return <RequestLicensePlate {...pageProps} />
    default:
      // 404 page
      if (pageProps && 'craftDataPage' in pageProps) {
        return <CraftPage {...pageProps} />
      }

      console.error(
        `Unkown Craft entry type '${resolvedRoute?.entryType}', rendering 404 page instead`,
      )
      break
  }

  return <NextError statusCode={404} />
}

export default CraftEntrySwitch
