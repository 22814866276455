import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React from 'react'

import { minWidth, maxWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import InformationSubjectMenu from '../../../components/InformationSubjectMenu'
import InformationSubjectMenuSimple from '../../../components/InformationSubjectMenuSimple'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import PageHeader from '../../../components/PageHeader'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../lib/getSeoPageTitle'
import theme from '../../../theme'
import { CraftInformationStaticData } from './useInformation'

const StyledContainer = styled(Container)`
  padding-bottom: ${theme.spacing['2xl']};
  padding-top: ${theme.spacing['2xl']};

  @media ${minWidth('lg')} {
    padding-bottom: ${theme.spacing['3xl']};
    padding-top: ${theme.spacing['3xl']};
  }
`

const StyledInformationSubjectMenuSimpleMobile = styled(
  InformationSubjectMenuSimple,
)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledInformationSubjectMenuTabletAndDesktop = styled(
  InformationSubjectMenu,
)`
  @media ${maxWidth('sm')} {
    display: none;
  }
`

type Props = CraftInformationStaticData

const InformationOverview = ({
  craftDataInformation,
  craftDataInformationSubjects: overviews,
  craftDataInformationItems: items,
}: Props) => {
  const informationContent =
    craftDataInformation.__typename === 'CraftInformationOverviewEntry'
      ? craftDataInformation
      : undefined

  const headerImage = informationContent?.image?.[0]

  return (
    <>
      <Meta
        metaTitle={
          informationContent?.metaTitle ??
          getSeoPageTitle(t({ message: 'Information' }))
        }
        metaDescription={
          informationContent?.metaDescription ?? getSeoPageDescription()
        }
      />

      <MetaCanonical />

      <section>
        {informationContent?.title && headerImage && (
          <PageHeader
            title={informationContent.title}
            subtitle={informationContent.subtitle ?? undefined}
            image={headerImage}
          />
        )}

        <StyledContainer>
          <StyledInformationSubjectMenuSimpleMobile
            craftDataInformationSubjects={overviews}
          />

          <StyledInformationSubjectMenuTabletAndDesktop
            craftDataInformationSubjects={overviews}
            craftDataInformationItems={items}
          />
        </StyledContainer>
      </section>
    </>
  )
}

export default InformationOverview
