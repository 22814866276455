import styled from '@emotion/styled'
import Image from 'next/image'
import React, { ComponentProps } from 'react'

interface ImageProps extends Omit<ComponentProps<typeof Image>, 'objectFit'> {
  objectFit?: 'cover' | 'contain'
}

const Figure = styled.figure`
  margin: 0;
  font-size: 0; // remove unwanted space
`

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => !['objectFit'].includes(prop.toString()),
})<ImageProps>`
  object-fit: ${({ objectFit }) => objectFit};
`

/**
 * Use @emico/image component for images
 * If @emico/image cannot be used for a specific context, use this component.
 * This NextImage component replaces <img /> usage, which is inadvisable for next.js:
 * "Do not use <img>. Use Image from 'next/image' instead. See https://nextjs.org/docs/messages/no-img-element."
 */

interface Props extends ImageProps {
  alt: string
  className?: string
}

const NextImage = ({ className, alt, ...other }: Props) => (
  <Figure className={className}>
    <StyledImage alt={alt} {...other} />
  </Figure>
)

export default NextImage
